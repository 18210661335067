import React, { useState } from 'react';
import { TradingViewChart } from './TradingViewChart';
import { FundingRateComponent } from './FundingRatecomponent';
import { OrderBookHistogramComponent } from './OrderbookHistogram';
import { Input } from '../ui/input';
import { Button } from '../ui/Button';

export function ChartComponent() {
    const [symbol, setSymbol] = useState('BTCUSDT');
    const [searchSymbol, setSearchSymbol] = useState('BTCUSDT');
    const [selectedChart, setSelectedChart] = useState('tradingview');
    const [loading, setLoading] = useState(false);

    const handleSymbolChange = (e) => {
        setSymbol(e.target.value.toUpperCase());
    };

    const handleFetch = () => {
        setLoading(true);
        setSearchSymbol(symbol);
        setLoading(false);
    };

    const chartButtons = [
        { id: 'tradingview', label: 'TradingView' },
        { id: 'fundingrate', label: 'Funding Rate' },
        { id: 'orderbook', label: 'Orderbook' }
    ];
    
    return (
        <div className="w-full" style={{ height: '800px' }}>
            <div className="space-y-6">
                {/* Search Bar */}
                <div className="flex space-x-2 items-center mb-4">
                    <Input
                        placeholder="Enter symbol (e.g., BTCUSDT)"
                        value={symbol}
                        onChange={handleSymbolChange}
                        className="flex-grow h-10 bg-gray-700 text-white"
                    />
                    <Button 
                        onClick={handleFetch} 
                        disabled={!symbol || loading}
                        className="h-10 px-6 bg-purple-600 hover:bg-purple-700 text-white"
                    >
                        {loading ? 'Loading...' : 'Fetch'}
                    </Button>
                </div>

                {/* Chart Type Buttons */}
                <div className="flex justify-center space-x-4 mb-4">
                    {chartButtons.map(button => (
                        <button
                            key={button.id}
                            onClick={() => setSelectedChart(button.id)}
                            className={`px-6 py-2 rounded-lg transition-colors ${
                                selectedChart === button.id 
                                    ? 'bg-blue-600 text-white' 
                                    : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                            }`}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>

                {/* Chart Display */}
                <div className="mt-6">
                    {selectedChart === 'tradingview' && (
                        <TradingViewChart symbol={searchSymbol} />
                    )}
                    {selectedChart === 'fundingrate' && (
                        <FundingRateComponent symbol={searchSymbol} />
                    )}
                    {selectedChart === 'orderbook' && (
                        <OrderBookHistogramComponent symbol={searchSymbol} />
                    )}
                </div>
            </div>
        </div>
    );
}
