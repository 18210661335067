import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import cn from 'classnames';
import ScrollArea from 'react-scrollbar';
import { Link } from 'react-router-dom';
import { Dashboard as DashboardIcon, Add as AddIcon, AccountBalanceWallet as WalletIcon, TrendingUp as TrendingUpIcon, SmartToy as AIIcon, BarChart as CoingeckoIcon, GridOn as UltraIcon, TableChart as SheetsIcon } from '@mui/icons-material';
import { AutomatedSettingsComponent } from './tradingComponents/AutomationSwitchComponent';
import { useUser } from '../context/UserContext';
import { addExchange, getConnectedExchanges ,deleteExchange } from '../services/api';
import './Sidebar.css';


export function Sidebar(props) {
  const { user } = useUser();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [connectedExchanges, setConnectedExchanges] = useState([]);
  const [exchangeData, setExchangeData] = useState({
    exchangeName: '',
    apiKey: '',
    apiSecret: '',
    passphrase: ''
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchConnectedExchanges = async () => {
      if (user && user.username) {
        const exchanges = await getConnectedExchanges(user.username);
        setConnectedExchanges(exchanges);
      }
    };

    fetchConnectedExchanges();
  }, [user]);

  const handleAddExchange = async () => {
    if (!user || !user.username) {
      alert('User not logged in');
      return;
    }

    const payload = {
      username: user.username, // Ensure username is included
      exchange: exchangeData.exchangeName, // Map exchangeName to exchange
      apiKey: exchangeData.apiKey,
      apiSecret: exchangeData.apiSecret,
      passphrase: exchangeData.passphrase || '' // Include passphrase if needed
    };

    console.log('Payload:', payload);
    const result = await addExchange(payload);
    if (result.success) {
      alert('Exchange added successfully');
      setOpen(false);
    } else {
      alert(result.message || 'Failed to add exchange');
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExchangeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDeleteExchange = async (exchange) => {
    const payload = {
      username: user.username,
      exchange: exchange
    };
    const result = await deleteExchange(payload);
    if (result.success) {
      setConnectedExchanges((prevExchanges) =>
        prevExchanges.filter((e) => e !== exchange)
      );
    } else {
      alert('Failed to delete exchange');
    }
  };


  const navItems = [
    { name: "Dashboard", icon: DashboardIcon, href: "/trading/dashboard", link: true },
    { name: "Portfolio", icon: WalletIcon, href: "/portfolio", link: true },
    { name: "Trading", icon: TrendingUpIcon, href: "/trading", link: true },
    { name: "Spekule AI", icon: AIIcon, href: "/spekule-GPT", link: true },
    { name: "Coingecko", icon: CoingeckoIcon, href: "/coingecko", link: true },
    { name: "Ultra", icon: UltraIcon, href: "/ultra", link: true },
    { name: "Screamer", icon: SheetsIcon, href: "/screamer", link: true },
  ]

  return (
    <div
      className={cn(
        "relative flex flex-col border-r bg-background font-montserrat",
        isCollapsed ? "w-16" : "w-64"
      )}
    >
      <div className="flex h-16 items-center justify-start px-4">
        {!isCollapsed && (
          <span className="text-lg font-montserrat-extrabold text-left brand-name">Spekule</span>
        )}
        <Button
          variant="ghost"
          size="icon"
          className="ml-auto hover:bg-gray-200"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <ChevronRight className="h-4 w-4 transition-transform" />
        </Button>
      </div>
      <ScrollArea className="flex-1" style={{ textAlign: 'left' }}>
        <nav className="flex flex-col gap-2 p-2">
          {navItems.map((item) => (
            <Link key={item.name} to={item.href}>
              <Button
                variant="ghost"
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  '&:hover': {
                    backgroundColor: '#020817',
                    color: '#00bcd4',
                  }
                }}
                className={cn(
                  "w-full justify-start text-left hover:bg-gray-200 hover:text-[#020817] font-montserrat-medium"
                )}
              >
                <item.icon className="mr-2 h-4 w-4" />
                {!isCollapsed && <span className="text-left">{item.name}</span>}
              </Button>
            </Link>
          ))}
        </nav>
      </ScrollArea>
      {!isCollapsed && (
        <div className="p-4 border-t border-gray-800">
          <button onClick={() => setOpen(true)} className="flex items-center justify-between w-full font-montserrat-medium">
            <span className="text-sm text-gray-400">Add Exchange</span>
            <AddIcon className="ml-2" />
          </button>
          {open && (
            <div className="popup">
              <h3 className="text-sm text-gray-400 text-right font-montserrat-semibold">Add Exchange</h3>
              
              <div className="connected-exchanges mt-4">
                <h4 className="text-sm text-gray-400 mb-2 font-montserrat-medium">Connected Exchanges</h4>
                <div className="flex flex-wrap gap-2">
                  {connectedExchanges.length > 0 ? (
                    connectedExchanges.map((exchange) => (
                      <span 
                        key={exchange} 
                        className="text-sm text-green-500 bg-gray-800 px-2 py-1 rounded-full cursor-pointer hover:bg-red-500 font-montserrat-medium"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to delete ${exchange} ?`)) {
                            handleDeleteExchange(exchange);
                          }
                        }}
                      >
                        {exchange}
                      </span>
                    ))
                  ) : (
                    <span className="text-sm text-gray-300 mb-2 font-montserrat-light">No exchanges connected. Connect an exchange to start trading.</span>
                  )}
                </div>
              </div>
              <form onSubmit={(e) => { e.preventDefault(); handleAddExchange(); }} className="font-montserrat">
                <select
                  name="exchangeName"
                  value={exchangeData.exchangeName}
                  onChange={handleInputChange}
                  className="font-montserrat-medium"
                >
                  <option value="">Select Exchange</option>
                  {['binance', 'kucoin', 'bitget', 'bybit']
                    .filter(exchange => !connectedExchanges.includes(exchange))
                    .map(exchange => (
                      <option key={exchange} value={exchange} className="font-montserrat-medium">
                        {exchange.charAt(0).toUpperCase() + exchange.slice(1)}
                      </option>
                    ))}
                </select>
                <input
                  type="text"
                  name="apiKey"
                  placeholder="API Key"
                  value={exchangeData.apiKey}
                  onChange={handleInputChange}
                  className="font-montserrat-regular"
                />
                <input
                  type="text"
                  name="apiSecret"
                  placeholder="API Secret"
                  value={exchangeData.apiSecret}
                  onChange={handleInputChange}
                  className="font-montserrat-regular"
                />
                {(exchangeData.exchangeName === 'bitget' || exchangeData.exchangeName === 'kucoin') && (
                  <input
                    type="text"
                    name="passphrase"
                    placeholder="Passphrase"
                    value={exchangeData.passphrase}
                    onChange={handleInputChange}
                    className="font-montserrat-regular"
                  />
                )}
                <button type="submit">Submit</button>
                <button type="button" onClick={() => setOpen(false)}>Cancel</button>
              </form>
            </div>
          )}
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-400">AUTOMATION</span>
            <AutomatedSettingsComponent />
          </div>
        </div>
      )}
    </div>
  );


}
