import React from "react";
import { Button } from "./Button";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const Heroing = () => {
  const navigate = useNavigate();

  return (
    <section className="min-h-screen flex items-center justify-center px-6 pt-20 relative">
      <div className="absolute inset-0 flex items-center justify-center opacity-[0.07] pointer-events-none">
        <img
          src="/logoSpekuleTransparent.png"
          alt="Spekule"
          className="w-[800px] h-[800px] object-contain"
        />
      </div>

      <div className="max-w-7xl mx-auto text-center relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-8"
        >
          <div className="relative">
            <div className="absolute inset-0 flex items-center justify-center animate-float">
              <div className="w-32 h-32 bg-spekule-purple/20 rounded-full blur-3xl" />
            </div>
            <h1 className="text-6xl md:text-8xl font-clash font-bold bg-gradient-to-r from-[#40A2E3] via-[#8E44AD] to-[#FF1493] text-transparent bg-clip-text">
              Spekule
            </h1>
          </div>

          <p className="text-xl md:text-2xl text-gray-400 max-w-2xl mx-auto">
            Experience the future of crypto trading with our advanced platform.
            Fast, secure, and intuitive.
          </p>

          <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6">
            <Button
              size="lg"
              className="bg-gradient-to-r from-[#8E44AD] to-[#FF1493] hover:opacity-90 text-white px-8 py-3 rounded-full text-lg"
              onClick={() => navigate('/register')}
            >
              Get Started
            </Button>
            <Button
              variant="link"
              size="lg"
              className="text-white hover:text-spekule-cyan transition-colors flex items-center"
            >
              Learn more →
            </Button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};