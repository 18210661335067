import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const PostHalvingDynamics = ({ language }) => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? 'Post-Halving Dynamics' : 'ديناميكيات ما بعد التنصف'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-center mb-8">
          <img
            src="/Post-Halvingchart.png"
            alt={language === 'en' ? 'Post-Halving Chart' : 'الرسم البياني ما بعد التنصف'}
            className="max-w-full h-auto"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">
              {language === 'en' ? 'Historical Performance' : 'الأداء التاريخي'}
            </h3>
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-blue-900 font-medium mb-2">
                {language === 'en' ? 'Post-Halving Year Returns:' : 'عائدات سنة ما بعد النصف:'}
              </p>
              <ul className={`list-disc space-y-2 text-blue-800 ${language === 'ar' ? 'text-right pr-4 rtl' : 'pl-4'}`}>
                <li>{language === 'en' ? '2013: 5,372.09%' : '2013: 5,372.09%'}</li>
                <li>{language === 'en' ? '2017: 1,331.17%' : '2017: 1,331.17%'}</li>
                <li>{language === 'en' ? '2021: 59.4%' : '2021: 59.4%'}</li>
              </ul>
              <p className="mt-4 text-blue-900">
                {language === 'en' ? 'Average: 2,520.22%' : 'المتوسط: 2,520.22%'}
              </p>
              <p className="mt-4 text-blue-900">
                {language === 'en'
                  ? 'Historical trends reveal that Bitcoin’s post-halving years deliver its most extraordinary returns, highlighting the strategic timing for accumulation.'
                  : 'تكشف الاتجاهات التاريخية أن سنوات ما بعد التنصف للبيتكوين تحقق أعلى العوائد، مما يبرز التوقيت الاستراتيجي للتجميع.'}
              </p>
              <p className="mt-4 text-blue-900">
                {language === 'en'
                  ? '• Post-Halving Year Average Returns (2013, 2017, 2021): (5372.09% + 1331.17% + 59.4%) / 3 = 2520.22%'
                  : '• متوسط عائدات سنوات ما بعد التنصف (2013، 2017، 2021): (5372.09% + 1331.17% + 59.4%) / 3 = 2520.22%'}
              </p>
              <p className="mt-4 text-blue-900">
                {language === 'en'
                  ? '• Non-Halving Year Average Returns: ((186.11 + (-56.40) + 33.11 + 126.19 + (-73.41) + 94.11 + 304.36 + (-64.22) + 155.78 + 124.61)) / 10 = 83.52%'
                  : '• متوسط عائدات السنوات غير التنصفية: ((186.11 + (-56.40) + 33.11 + 126.19 + (-73.41) + 94.11 + 304.36 + (-64.22) + 155.78 + 124.61)) / 10 = 83.52%'}
              </p>
            </div>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">
              {language === 'en' ? 'Bitcoin Strategic Reserve (BSR)' : 'احتياطي البيتكوين الاستراتيجي (BSR)'}
            </h3>
            <div className="bg-purple-50 p-4 rounded-lg">
              <p className="text-purple-900">
                {language === 'en'
                  ? "Senator Cynthia Lummis's proposal aims to acquire 1M BTC (5% of total supply) over 5 years, potentially creating a significant supply shock and driving unprecedented price growth."
                  : 'يهدف اقتراح السيناتور سينثيا لوميس إلى الحصول على 1 مليون بيتكوين (5٪ من إجمالي العرض) على مدى 5 سنوات، مما قد يخلق صدمة عرض كبيرة ويدفع نمو الأسعار بشكل غير مسبوق.'}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PostHalvingDynamics;