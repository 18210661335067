import React from 'react';
import ReturnsChartcomp from './ReturnsChart';

export function ReturnsComponent({ returnsdata }) {
  console.log("returns data",returnsdata);
  return (
    <div>
      <ReturnsChartcomp returnsData={returnsdata} />
    </div>
  );
}