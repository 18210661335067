// In your frontend, create a component to handle the callback
import React, { useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { setUserContext } from '../../context/UserContext';
import { verifyGoogleToken } from '../../services/api';
function GoogleCallback() {
    console.log("GoogleCallback");
  const navigate = useNavigate();
  const { setUser } = useUser();
  

  useEffect(() => {
    async function handleCallback() {
      const hash = window.location.hash.substring(1); 
      console.log("hash", hash);
      const params = new URLSearchParams(hash);
      console.log("params", params);
      const token = params.get('access_token');
      console.log("token", token);
      if (token) {
        // Verify token with your backend
        const userData = await verifyGoogleToken(token);
        console.log("userData", userData);
        if (userData) {
          setUserContext(setUser, {
            username: userData.username,
            role: userData.role,
            accessToken: userData.accessToken,
            email: userData.email
          });
//nrew
          // Check if "Remember Me" was selected and store the token

            localStorage.setItem('sessionToken', userData.accessToken);
            localStorage.setItem('username', userData.username);
            localStorage.setItem('role', userData.role);


          navigate(userData.role === 'client' ? '/client-dashboard' : '/trading/dashboard');
        } else {
          navigate('/login');
        }
      }
    }

    handleCallback();
  }, [navigate, setUser]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 to-black">
      <div className="text-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
        <h2 className="text-2xl font-semibold text-white mb-2">Authenticating...</h2>
        <p className="text-gray-400">Please wait while we verify your credentials</p>
      </div>
    </div>
  );
}

export default GoogleCallback;