import React from "react";
import { motion } from "framer-motion";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Jan', value: 40, prediction: 42 },
  { name: 'Feb', value: 45, prediction: 44 },
  { name: 'Mar', value: 55, prediction: 52 },
  { name: 'Apr', value: 48, prediction: 50 },
  { name: 'May', value: 52, prediction: 54 },
  { name: 'Jun', value: 62, prediction: 60 },
];

export const Targeting = () => {
  return (
    <section className="py-20 px-6 relative">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="grid md:grid-cols-2 gap-12 items-center"
        >
          <div className="space-y-6">
            <h2 className="text-4xl md:text-5xl font-clash font-bold">
              <span className="text-white">We Are</span>{" "}
              <span className="text-spekule-cyan">Targeting</span>
            </h2>
            <div className="bg-zinc-900/80 backdrop-blur-sm rounded-2xl p-8 border border-zinc-800 hover:bg-zinc-800/80 transition-all duration-300 hover:scale-[1.02] space-y-4">
              <p className="text-gray-300">
                <span className="text-spekule-pink font-bold">Our strength</span> in trend identification comes from our trend probability models (TPI) — adaptive, mechanical algorithms that excel at recognizing market trends.
              </p>
              <p className="text-gray-300">
                As you can see in the chart, our algorithm adapts to market conditions and excels in identifying predominant trends, consistently predicting market movements with high accuracy.
              </p>
            </div>
          </div>
          <div className="relative bg-zinc-900/80 backdrop-blur-sm rounded-2xl p-6 border border-zinc-800 hover:bg-zinc-800/80 transition-all duration-300 hover:scale-[1.02]">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" stroke="#ffffff20" />
                <XAxis dataKey="name" stroke="#ffffff80" />
                <YAxis stroke="#ffffff80" />
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#000000dd',
                    border: '1px solid #ffffff20',
                    borderRadius: '8px'
                  }}
                />
                <Line 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#00F5FF" 
                  strokeWidth={2}
                  dot={{ fill: '#00F5FF' }}
                  name="Actual"
                />
                <Line 
                  type="monotone" 
                  dataKey="prediction" 
                  stroke="#FF1493" 
                  strokeWidth={2}
                  dot={{ fill: '#FF1493' }}
                  name="Prediction"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </motion.div>
      </div>
    </section>
  );
};