import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';

function transformData(data) {
  if (!Array.isArray(data) || data.length === 0) {
    console.error('Data is not iterable or is empty:', data);
    return [];
  }
  const [headers, ...rows] = data;
  return rows.map(row => {
    const entry = {};
    headers.forEach((header, index) => {
      entry[header] = row[index];
    });
    return entry;
  });
}

const EquityCurveChart = ({ equityCurvedata }) => {
  const [transformedData, setTransformedData] = useState([]);
  const [disabledLines, setDisabledLines] = useState({});

  useEffect(() => {
    if (equityCurvedata && equityCurvedata.length > 0) {
      const data = transformData(equityCurvedata);
      setTransformedData(data);
    } else {
      setTransformedData([]);
    }
  }, [equityCurvedata]);

  const handleLegendClick = (dataKey) => {
    setDisabledLines(prevState => ({
      ...prevState,
      [dataKey]: !prevState[dataKey]
    }));
  };

  const spekuleTextStyle = {
    color: '#00d1b2',
    fontWeight: 'bold',
    fontSize: '1.2em',
    opacity: disabledLines['Spekule'] ? 0.5 : 1
  };

  const renderTooltip = ({ payload, label }) => {
    if (!payload || payload.length === 0) return null;

    const sortedPayload = payload.sort((a, b) => (a.name === 'Spekule' ? -1 : 1));

    let formattedDate = label;
    try {
      formattedDate = format(new Date(label), 'yyyy-MM-dd');
    } catch (error) {
      console.error('Invalid date:', label);
    }

    return (
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '10px', border: '1px solid #ccc', textAlign: 'center' }}>
        <p style={{ color: 'white', fontWeight: 'bold' }}>{formattedDate}</p>
        {sortedPayload.map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{
              color: entry.name === 'Spekule' ? 'transparent' : entry.color,
              background: entry.name === 'Spekule' ? '#00d1b2' : 'none',
              WebkitBackgroundClip: entry.name === 'Spekule' ? 'text' : 'none',
              fontWeight: 'bold',
              opacity: 1
            }}
          >
            {entry.name} : {entry.value}
          </p>
        ))}
      </div>
    );
  };

  // const lastDataPoints = transformedData.length > 0 ? {
  //   spekule: transformedData[transformedData.length - 1].Spekule,
  //   sp500: transformedData[transformedData.length - 1]['S&P 500'],
  //   btcusd: transformedData[transformedData.length - 1].BTCUSD,
  //   ethusd: transformedData[transformedData.length - 1].ETHUSD, 
  //   gold: transformedData[transformedData.length - 1].Gold
  // } : {};

  return (
    <Card className="col-span-3 relative">
      <CardHeader className="flex justify-between items-center mt-4">
        <CardTitle>Equity Curves for All Series </CardTitle>
      </CardHeader>
      <CardContent>
        {/* <div className="bg-gray-800/50 rounded-xl p-3 mb-4 backdrop-blur-sm shadow-xl absolute top-4 left-[90px] z-10" style={{maxWidth: "200px"}}>
          <div className="flex flex-col gap-2">
            <h3 className="text-white text-xs font-medium mb-1">End Result</h3>
            {Object.entries(lastDataPoints).map(([key, value]) => {
              const isPositive = value >= 0;
              return (
                <div key={key} className="flex items-center justify-between gap-2">
                  <h4 className="text-gray-400 text-xs font-medium">{key.toUpperCase()}</h4>
                  <p className={`text-sm font-bold ${isPositive ? 'text-emerald-400' : 'text-red-400'}`}>
                    {(value * 100).toFixed(2)}%
                  </p>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="bg-gray-800/50 rounded-xl p-3 mb-4 backdrop-blur-sm shadow-xl absolute top-18 left-[90px] z-10" style={{maxWidth: "200px"}}>
          <div className="flex flex-col gap-2">
            <h3 className="text-white text-xs font-medium mb-1">Q4 % Returns</h3>
            <div className="flex items-center justify-between gap-2">
              <h4 className="text-gray-400 text-xs font-medium">SPEKULE</h4>
              <p className="text-sm font-bold" style={{background: 'linear-gradient(90deg, #00d1b2 0%, #4CAF50 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>58.00%</p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <h4 className="text-gray-400 text-xs font-medium">GOLD</h4>
              <p className="text-sm font-bold" style={{background: 'linear-gradient(90deg, #FFD700 0%, #FFA500 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>5.00%</p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <h4 className="text-gray-400 text-xs font-medium">S&P 500</h4>
              <p className="text-sm font-bold" style={{background: 'linear-gradient(90deg, #FF4C4C 0%, #FF0000 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>1.00%</p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <h4 className="text-gray-400 text-xs font-medium">BTCUSD</h4>
              <p className="text-sm font-bold" style={{background: 'linear-gradient(90deg, #FFA500 0%, #FF8C00 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>48.00%</p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <h4 className="text-gray-400 text-xs font-medium">ETHUSD</h4>
              <p className="text-sm font-bold" style={{background: 'linear-gradient(90deg, #4C4CFF 0%, #0000FF 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>30.00%</p>
            </div>
          </div>
        </div>


        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={transformedData}>
            <XAxis 
              dataKey="Date"
              tickFormatter={(date) => {
                try {
                  return format(new Date(date), 'MMM dd, yyyy');
                } catch {
                  return date;
                }
              }}
            />                        
            <YAxis domain={[1, 'auto']} />
            <Tooltip content={renderTooltip} />
            <Legend 
              onClick={(e) => handleLegendClick(e.dataKey)} 
              formatter={(value) => (
                <span style={value === 'Spekule' ? spekuleTextStyle : { opacity: disabledLines[value] ? 0.5 : 1 }}>
                  {value}
                </span>
              )}
            />
            <Line type="linear" dataKey="Spekule" stroke="#00d1b2" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['Spekule'] ? 0.2 : 1} />
            <Line type="linear" dataKey="S&P 500" stroke="#FF0000" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['S&P 500'] ? 0.2 : 1} />
            <Line type="linear" dataKey="BTCUSD" stroke="#FFA500" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['BTCUSD'] ? 0.2 : 1} />
            <Line type="linear" dataKey="ETHUSD" stroke="#0000FF" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['ETHUSD'] ? 0.2 : 1} />
            <Line type="linear" dataKey="Gold" stroke="#FFFF00" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['Gold'] ? 0.2 : 1} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default EquityCurveChart;