import React, { useState, useEffect } from 'react';
import { fetchTokensByMarketCap } from '../../services/api';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    Link,
    IconButton,
    Modal,
    Box,
    TextField,
    Button,
    Card,
    CardContent,
    Chip,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import TablePaginationActions from './TablePaginationActions';
import './CoinGeckocomponent.css';

export function CoinGeckoComponent() {
    const [tokens, setTokens] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [marketCapFilter, setMarketCapFilter] = useState({ min: '', max: '' });
    const [percentageChangeFilter, setPercentageChangeFilter] = useState({ min: '', max: '' });
    const [totalVolumeFilter, setTotalVolumeFilter] = useState({ min: '', max: '' });
    const [currentPriceFilter, setCurrentPriceFilter] = useState({ min: '', max: '' });
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('marketCap');
    const [open, setOpen] = useState(false);
    const tokensCache = useState(null);

    useEffect(() => {
        const loadTokensByMarketCap = async () => {
            if (tokensCache.current) {
                setTokens(tokensCache.current);
                return;
            }
            try {
                const tokensData = await fetchTokensByMarketCap(1);
                tokensCache.current = tokensData;
                setTokens(tokensData);
            } catch (error) {
                console.error('Error loading tokens:', error);
            }
        };

        loadTokensByMarketCap();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        if (name.includes('marketCap')) {
            setMarketCapFilter(prev => ({ ...prev, [name.split('.')[1]]: value }));
        } else if (name.includes('percentageChange')) {
            setPercentageChangeFilter(prev => ({ ...prev, [name.split('.')[1]]: value }));
        } else if (name.includes('totalVolume')) {
            setTotalVolumeFilter(prev => ({ ...prev, [name.split('.')[1]]: value }));
        } else if (name.includes('currentPrice')) {
            setCurrentPriceFilter(prev => ({ ...prev, [name.split('.')[1]]: value }));
        }
    };

    const applyFilter = () => {
        setOpen(false);
    };

    const clearFilter = (filterType, key) => {
        if (filterType === 'marketCap') {
            setMarketCapFilter(prev => ({ ...prev, [key]: '' }));
        } else if (filterType === 'percentageChange') {
            setPercentageChangeFilter(prev => ({ ...prev, [key]: '' }));
        } else if (filterType === 'totalVolume') {
            setTotalVolumeFilter(prev => ({ ...prev, [key]: '' }));
        } else if (filterType === 'currentPrice') {
            setCurrentPriceFilter(prev => ({ ...prev, [key]: '' }));
        }
    };

    const filteredTokens = tokens
        .filter(token => {
            const minCap = parseFloat(marketCapFilter.min) || 0;
            const maxCap = parseFloat(marketCapFilter.max) || Infinity;
            const minChange = parseFloat(percentageChangeFilter.min) || -Infinity;
            const maxChange = parseFloat(percentageChangeFilter.max) || Infinity;
            const minVolume = parseFloat(totalVolumeFilter.min) || 0;
            const maxVolume = parseFloat(totalVolumeFilter.max) || Infinity;
            const minPrice = parseFloat(currentPriceFilter.min) || 0;
            const maxPrice = parseFloat(currentPriceFilter.max) || Infinity;

            return (
                token.market_cap >= minCap &&
                token.market_cap <= maxCap &&
                token.price_change_percentage_24h >= minChange &&
                token.price_change_percentage_24h <= maxChange &&
                token.total_volume >= minVolume &&
                token.total_volume <= maxVolume &&
                token.current_price >= minPrice &&
                token.current_price <= maxPrice
            );
        })
        .sort((a, b) => {
            if (sortField === 'marketCap') {
                return sortOrder === 'asc' ? a.market_cap - b.market_cap : b.market_cap - a.market_cap;
            } else if (sortField === 'percentageChange') {
                return sortOrder === 'asc' ? a.price_change_percentage_24h - b.price_change_percentage_24h : b.price_change_percentage_24h - a.price_change_percentage_24h;
            } else if (sortField === 'totalVolume') {
                return sortOrder === 'asc' ? a.total_volume - b.total_volume : b.total_volume - a.total_volume;
            } else if (sortField === 'currentPrice') {
                return sortOrder === 'asc' ? a.current_price - b.current_price : b.current_price - a.current_price;
            }
        });

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <IconButton onClick={handleOpen} style={{ color: 'white' }}>
                    <FilterListIcon />
                </IconButton>
                {marketCapFilter.min && (
                    <Chip
                        label={`Min Cap: ${parseFloat(marketCapFilter.min).toLocaleString()}`}
                        onDelete={() => clearFilter('marketCap', 'min')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {marketCapFilter.max && (
                    <Chip
                        label={`Max Cap: ${parseFloat(marketCapFilter.max).toLocaleString()}`}
                        onDelete={() => clearFilter('marketCap', 'max')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {percentageChangeFilter.min && (
                    <Chip
                        label={`Min %: ${parseFloat(percentageChangeFilter.min).toLocaleString()}`}
                        onDelete={() => clearFilter('percentageChange', 'min')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {percentageChangeFilter.max && (
                    <Chip
                        label={`Max %: ${parseFloat(percentageChangeFilter.max).toLocaleString()}`}
                        onDelete={() => clearFilter('percentageChange', 'max')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {totalVolumeFilter.min && (
                    <Chip
                        label={`Min Volume: ${parseFloat(totalVolumeFilter.min).toLocaleString()}`}
                        onDelete={() => clearFilter('totalVolume', 'min')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {totalVolumeFilter.max && (
                    <Chip
                        label={`Max Volume: ${parseFloat(totalVolumeFilter.max).toLocaleString()}`}
                        onDelete={() => clearFilter('totalVolume', 'max')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {currentPriceFilter.min && (
                    <Chip
                        label={`Min Price: ${parseFloat(currentPriceFilter.min).toLocaleString()}`}
                        onDelete={() => clearFilter('currentPrice', 'min')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {currentPriceFilter.max && (
                    <Chip
                        label={`Max Price: ${parseFloat(currentPriceFilter.max).toLocaleString()}`}
                        onDelete={() => clearFilter('currentPrice', 'max')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
                {sortOrder && (
                    <Chip
                        label={`Sort: ${sortField} ${sortOrder}`}
                        onDelete={() => setSortOrder('')}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: '#00e676', color: 'black' }}
                    />
                )}
            </div>
            <Modal open={open} onClose={handleClose} sx={{ backdropFilter: 'blur(5px)' }}>
                <Box sx={{ ...modalStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Card sx={{ bgcolor: '#2c2c2c', color: 'white', width: '90%', maxWidth: '500px', maxHeight: '80vh', overflowY: 'auto', borderRadius: '12px', boxShadow: 3 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px' }}>
                            <IconButton onClick={handleClose} style={{ alignSelf: 'flex-end', color: 'white' }}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h5" component="h2" style={{ marginBottom: '24px', textAlign: 'center', color: '#00e676' }}>
                                Filter and Sort
                            </Typography>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, width: '100%' }}>
                                <TextField
                                    label="Min Market Cap"
                                    name="marketCap.min"
                                    type="number"
                                    value={marketCapFilter.min}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Max Market Cap"
                                    name="marketCap.max"
                                    type="number"
                                    value={marketCapFilter.max}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Min % Change"
                                    name="percentageChange.min"
                                    type="number"
                                    value={percentageChangeFilter.min}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Max % Change"
                                    name="percentageChange.max"
                                    type="number"
                                    value={percentageChangeFilter.max}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Min Volume"
                                    name="totalVolume.min"
                                    type="number"
                                    value={totalVolumeFilter.min}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Max Volume"
                                    name="totalVolume.max"
                                    type="number"
                                    value={totalVolumeFilter.max}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Min Price"
                                    name="currentPrice.min"
                                    type="number"
                                    value={currentPriceFilter.min}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                                <TextField
                                    label="Max Price"
                                    name="currentPrice.max"
                                    type="number"
                                    value={currentPriceFilter.max}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    InputLabelProps={{ style: { color: '#bdbdbd' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                />
                            </Box>
                            <FormControl component="fieldset" style={{ marginTop: '16px', width: '100%' }}>
                                <FormLabel component="legend" style={{ color: '#bdbdbd' }}>Sort By</FormLabel>
                                <RadioGroup
                                    row
                                    value={sortField}
                                    onChange={(e) => setSortField(e.target.value)}
                                    style={{ justifyContent: 'center' }}
                                >
                                    <FormControlLabel value="marketCap" control={<Radio style={{ color: '#00e676' }} />} label="Market Cap" />
                                    <FormControlLabel value="percentageChange" control={<Radio style={{ color: '#00e676' }} />} label="Percentage Change" />
                                    <FormControlLabel value="totalVolume" control={<Radio style={{ color: '#00e676' }} />} label="Total Volume" />
                                    <FormControlLabel value="currentPrice" control={<Radio style={{ color: '#00e676' }} />} label="Current Price" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" style={{ marginTop: '16px', width: '100%' }}>
                                <FormLabel component="legend" style={{ color: '#bdbdbd' }}>Order</FormLabel>
                                <RadioGroup
                                    row
                                    value={sortOrder}
                                    onChange={(e) => setSortOrder(e.target.value)}
                                    style={{ justifyContent: 'center' }}
                                >
                                    <FormControlLabel value="asc" control={<Radio style={{ color: '#00e676' }} />} label="Ascending" />
                                    <FormControlLabel value="desc" control={<Radio style={{ color: '#00e676' }} />} label="Descending" />
                                </RadioGroup>
                            </FormControl>
                            <Button
                                variant="contained"
                                onClick={applyFilter}
                                style={{
                                    marginTop: '24px',
                                    backgroundColor: '#00e676',
                                    color: 'black',
                                    width: '100%'
                                }}
                            >
                                Apply
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Index</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Name</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Symbol</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Current Price (USD)</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Price Change 24h</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Total Volume</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Market Cap</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="center">Closest Fibonacci Level</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Fibonacci Levels</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredTokens
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((token, index) => (
                            <TableRow hover key={token.id || index} style={{ backgroundColor: '#1e1e1e' }}>
                                <TableCell align="center" style={{ color: '#00e676' }}>
                                    {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell>
                                    <Link
                                        href={`https://www.coingecko.com/en/coins/${token.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="hover"
                                        style={{ color: '#2196f3' }}
                                    >
                                        {token.name}
                                    </Link>
                                </TableCell>
                                <TableCell style={{ color: '#bdbdbd' }}>{token.symbol}</TableCell>
                                <TableCell align="right" style={{ color: '#ffeb3b' }}>
                                    ${token.current_price.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        style={{
                                            color: token.price_change_24h >= 0 ? '#4caf50' : '#f44336',
                                        }}
                                    >
                                        {token.price_change_24h
                                            ? `${token.price_change_24h.toFixed(2)}%`
                                            : 'N/A'}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" style={{ color: '#bdbdbd' }}>
                                    {token.total_volume.toLocaleString()}
                                </TableCell>
                                <TableCell align="right" style={{ color: '#bdbdbd' }}>
                                    ${token.market_cap.toLocaleString()}
                                </TableCell>
                                <TableCell align="center" style={{ color: '#bdbdbd' }}>
                                    {token.closestFibLevel}
                                </TableCell>
                                <TableCell>
                                    {token.fibLevels.map((level, i) => (
                                        <Typography variant="body2" key={i} style={{ color: '#bdbdbd' }}>
                                            {i + 1}: {level.toFixed(8)}
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredTokens.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{
                    position: 'sticky',
                    bottom: '0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: 0,
                    backgroundColor: 'black',
                    color: '#ffffff',
                    '& .MuiSelect-icon': {
                        color: '#00e676',
                    },
                }}
            />
        </div>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    maxHeight: '80vh',
    bgcolor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    p: 0,
};