import React, { useState, useEffect } from 'react';
import { fetchUserAssets, fetchPieChartData } from '../../../services/clientApi';
import PortfolioOverview from './PortfolioOverview';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const UserAssetsPieChart = ({ email }) => {
  console.log("email inside client assets", email)
  const [assetsData, setAssetsData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [profitLossUSD, setProfitLossUSD] = useState(0);
  const [profitLossPercentage, setProfitLossPercentage] = useState(0);
  const [pieChartData, setPieChartData] = useState({ piechart2: {}, piechart3: {}, piechart4: {} });
  const [time, setTime] = useState('');

  const extractPercentages = (data) => {
    if (!data || data.length < 2) return { labels: [], values: [] };
    const labels = data.slice(1).map(item => item[0].split(':')[0]);
    const values = data.slice(1).map(item => parseFloat(item[0].match(/(\d+)%/)[1]));
    return { labels, values };
  };

  const extractMajorAlt = (data) => {
    if (!data || data.length < 2) return { labels: [], values: [] };
    const labels = data[0];
    const values = data[1].map(item => parseFloat(item.replace('%', '')));
    return { labels, values };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pieData = await fetchPieChartData();
        setPieChartData({
          piechart2: extractMajorAlt(pieData.piechart2),
          piechart3: extractPercentages(pieData.piechart3),
          piechart4: extractPercentages(pieData.piechart4),
        });
        setTime(pieData.time);

        const clientCash = await fetchUserAssets(email);
        console.log("clientCash", clientCash);
        if (clientCash) {
          setTotalValue(clientCash.Deposit);
          setProfitLossUSD(clientCash.ProfitLossUsd);
          setProfitLossPercentage(clientCash.ProfitLossPercentage);
        }else{
          setTotalValue(0);
          setProfitLossUSD(0);
          setProfitLossPercentage(0);
        }

        // const userData = await fetchUserAssets(email);
        // if (userData && Array.isArray(userData.assets)) {
        //   // setAssetsData(userData.assets);
        //   setTotalValue(userData.totalAssetsValue || 0);
        //   setProfitLossUSD(userData.profitLossUSD || 0);
        //   setProfitLossPercentage(userData.profitLossPercentage || 0);
        // } else {
        //   setAssetsData([]);
        //   setTotalValue(0);
        //   setProfitLossUSD(0);
        //   setProfitLossPercentage(0);
        // }
      } catch (error) {
        console.error('Error fetching data:', error);
        setAssetsData([]);
        setTotalValue(0);
        setProfitLossUSD(0);
        setProfitLossPercentage(0);
      }
    };

    fetchData();
  }, [email]);

  const renderPieChart = (data, title, colors) => (
    data.labels && data.labels.length > 0 ? (
      <div style={{ width: '300px', height: '300px', margin: '20px' }}>
        <Pie
          data={{
            labels: data.labels,
            datasets: [{
              data: data.values,
              backgroundColor: colors,
            }],
          }}
          options={{
            plugins: {
              title: {
                display: true,
                text: title,
              },
              tooltip: {
                callbacks: {
                  label: (context) => `${context.label}: ${context.raw}%`,
                },
              },
            },
            maintainAspectRatio: false,
          }}
        />
      </div>
    ) : <p>No data available for {title}</p>
  );
  const colors = ['#FFFFFF', '#0d47a1', '#008080'];

  return (
    <div className="w-full mx-auto space-y-6">
      <PortfolioOverview
        totalValue={totalValue}
        profitLossUSD={profitLossUSD}
        profitLossPercentage={profitLossPercentage}
      />
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {renderPieChart(pieChartData.piechart2, 'MAJOR vs ALT', colors)}
        {renderPieChart(pieChartData.piechart3, 'BTC vs ETH', colors)}
        {renderPieChart(pieChartData.piechart4, 'Trading Portfolio', colors)}
      </div>
      <div>
        <h2>Time: {time}</h2>
      </div>
    </div>
  );
};

export default UserAssetsPieChart;