import { useState, useEffect } from 'react';
import Plotly from 'plotly.js-dist';

export function FundingRateComponent(props) {
  const {symbol: symbolProps} = props;
  const [symbol, setSymbol] = useState(symbolProps || "BTCUSDT");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState({
    label: '2 Weeks',
    periods: 42 // Default 14 days (42 8-hour periods)
  });
   
  useEffect(() => {
    if (symbolProps) {
      setSymbol(symbolProps);
    }
  }, [symbolProps]);
  
  useEffect(() => {
    handleFetchData();
  }, [symbol, timeframe.periods]);
  
  const handleFetchData = async () => {
    setLoading(true);
    try {
      const [ fundingResponse] = await Promise.all([
        fetch(`https://fapi.binance.com/fapi/v1/premiumIndex?symbol=${symbol}`),
        fetch(`https://fapi.binance.com/fapi/v1/fundingRate?symbol=${symbol}&limit=${timeframe.periods}`)
      ]);

      const fundingData = await fundingResponse.json();

      if (!Array.isArray(fundingData)) {
        throw new Error('Invalid funding rate data received');
      }

      plotFundingData(fundingData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Error fetching data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const plotFundingData = (fundingData) => {
    const x = fundingData.map(item => new Date(item.fundingTime).toISOString());
    const y = fundingData.map(item => item.fundingRate * 100);

    // Calculate statistics
    const avgRate = y.reduce((a, b) => a + b, 0) / y.length;
    const maxRate = Math.max(...y);
    const minRate = Math.min(...y);
    
    // Main funding rate bars
    const mainTrace = {
      x: x,
      y: y,
      type: 'bar',
      name: 'Funding Rate',
      marker: {
        color: y.map(value => {
          if (value > 0.01) return '#26a69a';      // Strong positive - green
          if (value > 0) return '#81c784';         // Weak positive - light green
          if (value > -0.01) return '#ef5350';     // Weak negative - light red
          return '#d32f2f';                        // Strong negative - dark red
        }),
        line: {
          color: '#ffffff',
          width: 1
        }
      },
      hovertemplate: '<b>Rate:</b> %{y:.4f}%<br>' +
                    '<b>Time:</b> %{x}<br>' +
                    '<extra></extra>'
    };

    // Moving average line
    const movingAverage = calculateMovingAverage(y, 6); // 2-day MA (6 periods)
    const maTrace = {
      x: x,
      y: movingAverage,
      type: 'scatter',
      mode: 'lines',
      name: '48h Moving Average',
      line: {
        color: '#ffeb3b',
        width: 2,
        dash: 'dot'
      }
    };

    // Average line
    const avgTrace = {
      x: [x[0], x[x.length-1]],
      y: [avgRate, avgRate],
      type: 'scatter',
      mode: 'lines',
      name: `Period Average: ${avgRate.toFixed(4)}%`,
      line: {
        color: '#2196f3',
        width: 2,
        dash: 'dash'
      }
    };

    const layout = {
      title: {
        text: `${symbol} Funding Rate Analysis (${timeframe.label})`,
        font: { color: 'white', size: 24 },
        y: 0.95
      },
      xaxis: {
        title: 'Time',
        type: 'date',
        color: 'white',
        gridcolor: '#333',
        showgrid: true,
        nticks: 8,
        tickangle: -45,
        automargin: true,
        fixedrange: true
      },
      yaxis: {
        title: 'Funding Rate (%)',
        tickformat: '.4f',
        color: 'white',
        gridcolor: '#333',
        showgrid: true,
        zeroline: true,
        zerolinecolor: '#666',
        automargin: true,
        titlefont: { size: 12 },
        fixedrange: true,
        range: [0, Math.max(maxRate * 1.1, 0.08)]
      },
      paper_bgcolor: '#000',
      plot_bgcolor: '#000',
      bargap: 0.05,
      margin: { 
        t: 80,
        r: 10,
        b: 100,
        l: 80,
        pad: 0
      },
      showlegend: true,
      legend: {
        x: 0.7,
        y: 1,
        font: { color: 'white' },
        bgcolor: 'rgba(0,0,0,0.5)',
        orientation: 'h',
        xanchor: 'right'
      },
      annotations: [{
        x: 0.02,
        y: 0.92,
        xref: 'paper',
        yref: 'paper',
        text: `Period Statistics:<br>` +
              `Average: ${avgRate.toFixed(4)}%<br>` +
              `Maximum: ${maxRate.toFixed(4)}%<br>` +
              `Minimum: ${minRate.toFixed(4)}%`,
        showarrow: false,
        font: { color: 'white', size: 12 },
        bgcolor: 'rgba(0,0,0,0.7)',
        bordercolor: 'white',
        borderwidth: 1,
        borderpad: 4,
        align: 'left'
      }]
    };

    const config = {
      responsive: true,
      displayModeBar: true,
      modeBarButtonsToAdd: ['zoom2d', 'pan2d', 'resetScale2d'],
      displaylogo: false,
      scrollZoom: false
    };

    Plotly.newPlot('fundingVStime', [mainTrace, maTrace, avgTrace], layout, config);
  };

  const calculateMovingAverage = (data, period) => {
    return data.map((val, idx, arr) => {
      if (idx < period - 1) return null;
      const sum = arr.slice(idx - period + 1, idx + 1).reduce((a, b) => a + b, 0);
      return sum / period;
    });
  };

  const handleTimeframeChange = (periods, label) => {
    setTimeframe({ periods, label });
  };
 
  return (
    <div className="funding-rate-container">
      <div className="mb-4 flex justify-center">
        <div className="inline-flex space-x-1">
          <button 
            onClick={() => handleTimeframeChange(42, '2W')} 
            className={`px-3 py-1 rounded text-sm ${timeframe.periods === 42 ? 'bg-blue-500' : 'bg-gray-700'} 
              hover:bg-blue-600 transition-colors duration-200`}
            title="2 Weeks"
          >
            2W
          </button>
          <button 
            onClick={() => handleTimeframeChange(90, '1M')} 
            className={`px-3 py-1 rounded text-sm ${timeframe.periods === 90 ? 'bg-blue-500' : 'bg-gray-700'}
              hover:bg-blue-600 transition-colors duration-200`}
            title="1 Month"
          >
            1M
          </button>
        </div>
      </div>
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
          </div>
        )}
        <div id="fundingVStime" style={{ width: '100%', height: '600px' }}></div>
      </div>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
}