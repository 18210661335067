import React from 'react';
import { DollarSign, ArrowUp, ArrowDown } from 'lucide-react';
import { useUser } from '../../../context/UserContext';

const PortfolioOverview = ({ totalValue, profitLossUSD, profitLossPercentage }) => {
  const { user } = useUser();
  const getColor = (value) => (value > 0 ? 'text-green-500' : 'text-red-500');

  return (
    <div className="glass-card p-6">
      <h1 className="text-2xl font-bold mb-6">{user.username}'s Portfolio Overview</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="space-y-2">
          <div className="text-gray-400 text-sm">Total Portfolio Value</div>
          <div className="text-2xl font-bold flex items-center">
            <DollarSign className="w-6 h-6 text-spekule-accent mr-2" />
            ${totalValue}
          </div>
        </div>
        
        <div className="space-y-2">
          <div className="text-gray-400 text-sm">Profit/Loss</div>
          <div className={`text-2xl font-bold flex items-center ${getColor(profitLossUSD)}`}>
            {profitLossUSD > 0 ? <ArrowUp className="w-6 h-6 mr-2" /> : <ArrowDown className="w-6 h-6 mr-2" />}
            ${profitLossUSD}
          </div>
        </div>
        
        <div className="space-y-2">
          <div className="text-gray-400 text-sm">Percentage</div>
          <div className={`text-2xl font-bold flex items-center ${getColor(profitLossPercentage)}`}>
            {profitLossPercentage}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioOverview;