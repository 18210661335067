import React from "react";
import { motion } from "framer-motion";
import { TrendingUp, Globe2, FileSpreadsheet, Users } from "lucide-react";

const philosophyItems = [
  {
    id: "01",
    icon: TrendingUp,
    title: "Identifying Market Trends",
    description: "Expertise in detecting trends before they emerge."
  },
  {
    id: "02",
    icon: Globe2,
    title: "Mastering Market Rotations",
    description: "Proficient in timing and rotating across dispersed markets."
  },
  {
    id: "03",
    icon: FileSpreadsheet,
    title: "Quantitative, Adaptive, & Repeatable Approach",
    description: "Data-driven strategies that evolve and consistently perform."
  },
  {
    id: "04",
    icon: Users,
    title: "Sophisticated Risk Management",
    description: "Advanced techniques to mitigate risk and protect returns."
  }
];

export const InvestmentPhilosophy = () => {
  return (
    <section className="py-20 px-6 relative">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-12"
        >
          <div className="text-center">
            <h2 className="text-4xl md:text-5xl font-clash font-bold">
              <span className="text-white">Investment</span>{" "}
              <span className="text-spekule-cyan">Philosophy</span>
            </h2>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {philosophyItems.map((item, index) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="relative"
              >
                <div className="bg-zinc-900/80 backdrop-blur-sm rounded-2xl p-8 h-full border border-zinc-800 hover:bg-zinc-800/80 transition-all duration-300 hover:scale-[1.02]">
                  <div className="absolute -top-4 -left-4 bg-spekule-purple/20 rounded-full p-2">
                    <div className="bg-zinc-900/90 backdrop-blur-sm rounded-full p-2">
                      <span className="text-spekule-cyan font-medium">
                        {item.id}
                      </span>
                    </div>
                  </div>
                  <div className="mb-6">
                    <item.icon className="w-12 h-12 text-spekule-pink" />
                  </div>
                  <h3 className="text-xl font-clash font-bold text-white mb-4">{item.title}</h3>
                  <p className="text-gray-400">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};