import React, { useState } from 'react';
import { ArrowUpRight, Globe, Globe2 } from "lucide-react";
import { ChartSection } from "./reportComponent/chartSection";
import { ProtocolCard } from "./reportComponent/protocolCard";
import { AirdropCard } from "./reportComponent/airdropCard";
import { Button } from "./ui/Button";
import { reportContent, reportContentAr } from "./reportComponent/reportContent";
import Navigation from "./ClientPortalComponents/ui/navigation";
import { mainReportContent, mainReportContent2, mainReportContentAr, mainReportContentAr2 } from "./reportComponent/mainReportContent";
import ReportHeader from './reportComponent/ReportHeader';
import MarketPerformance from './reportComponent/MarketPerformance';
import MacroeconomicChallenges from './reportComponent/MacroeconomicChallenges';
import PostHalvingDynamics from './reportComponent/PostHalvingDynamics';
import RegulatoryEnvironment from './reportComponent/RegulatoryEnvironment';
import SpekulePerformance from './reportComponent/SpekulePerformance';
import Conclusion from './reportComponent/Conclusion';
import Appendix from './reportComponent/Appendix';
import TrumpAdministration from './reportComponent/trumpadministration';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
const Index = () => {
    const [language, setLanguage] = useState('en');
    const [activeTab, setActiveTab] = useState('airdrop');
    const [activeTab2, setActiveTab2] = useState('main');

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ar' : 'en'));
    };

    return (
        <div className="min-h-screen bg-background text-foreground">
            <Navigation />
            <button
                onClick={toggleLanguage}
                className="absolute right-4 top-24 z-50 flex items-center gap-2 px-4 py-2 bg-white/90 backdrop-blur-sm border border-gray-200 rounded-lg shadow-lg hover:bg-gray-50 transition-all duration-200 hover:scale-105"
            >
                {language === 'en' ? (
                    <>
                        <Globe className="h-5 w-5 text-blue-600" />
                        <span className="font-medium text-sm text-black">Switch to Arabic</span>
                    </>
                ) : (
                    <>
                        <Globe2 className="h-5 w-5 text-emerald-600" />
                        <span className="font-medium text-sm text-black">التبديل إلى الإنجليزية</span>
                    </>
                )}
            </button>
            <div className="flex justify-center mt-16">
                <div className="bg-gray-800/50 backdrop-blur-sm p-1 rounded-lg flex gap-1">
                    <button
                        onClick={() => setActiveTab('airdrop')}
                        className={`px-6 py-2.5 rounded-md font-medium transition-all duration-200 ${
                            activeTab === 'airdrop' 
                            ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/30 hover:bg-blue-700' 
                            : 'text-gray-300 hover:text-white hover:bg-gray-700/50'
                        }`}
                    >
                        Airdrop
                    </button>
                    <button
                        onClick={() => setActiveTab('wordFile')}
                        className={`px-6 py-2.5 rounded-md font-medium transition-all duration-200 ${
                            activeTab === 'wordFile'
                            ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/30 hover:bg-blue-700'
                            : 'text-gray-300 hover:text-white hover:bg-gray-700/50'
                        }`}
                    >
                        Quarterly Report                    
                    </button>
                </div>
            </div>

            <main className={`container py-8 space-y-12 mt-16 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                {activeTab === 'airdrop' ? (
                    <>
                        {/* Introduction */}
                        <section className="space-y-8 section-fade">
                            <div className="max-w-4xl mx-auto">
                                <h2 className="text-4xl font-bold tracking-tight text-center mb-12">
                                    {language === 'en' ? 'Understanding Crypto Fundamentals' : 'فهم أساسيات العملات المشفرة'}
                                </h2>
                                
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                    {/* Intro to Airdrops Card */}
                                    <div className="bg-gradient-to-br from-blue-50 to-purple-50 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                                        <div className="h-12 w-12 bg-blue-500 rounded-lg mb-4 flex items-center justify-center">
                                            <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                        <h3 className="text-xl font-semibold mb-4 text-blue-900">
                                            {language === 'en' ? 'Introduction to Airdrops' : 'مقدمة للإيردروب'}
                                        </h3>
                                        <p className="text-gray-700">
                                            {language === 'en' 
                                                ? 'An airdrop is a strategic token distribution where companies reward loyal users with their cryptocurrency tokens. This creates market liquidity and generates organic marketing through community engagement.'
                                                : 'الإيردروب هو توزيع استراتيجي للرموز حيث تكافئ الشركات المستخدمين المخلصين برموز عملاتهم المشفرة. يخلق هذا سيولة في السوق ويولد تسويقًا عضويًا من خلال مشاركة المجتمع.'}
                                        </p>
                                    </div>

                                    {/* Chains Card */}
                                    <div className="bg-gradient-to-br from-green-50 to-teal-50 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                                        <div className="h-12 w-12 bg-green-500 rounded-lg mb-4 flex items-center justify-center">
                                            <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>
                                        <h3 className="text-xl font-semibold mb-4 text-green-900">
                                            {language === 'en' ? 'Blockchain Networks' : 'شبكات البلوكتشين'}
                                        </h3>
                                        <p className="text-gray-700">
                                            {language === 'en'
                                                ? 'Blockchains are the digital highways of crypto. Layer 1 chains like Bitcoin and Ethereum serve as main routes, while Layer 2 solutions like Arbitrum provide faster, cost-effective alternatives.'
                                                : 'البلوكتشين هي الطرق السريعة الرقمية للعملات المشفرة. تعمل سلاسل الطبقة 1 مثل بيتكوين وإيثريوم كطرق رئيسية، بينما توفر حلول الطبقة 2 مثل Arbitrum بدائل أسرع وفعالة من حيث التكلفة.'}
                                        </p>
                                    </div>

                                    {/* Protocols Card */}
                                    <div className="bg-gradient-to-br from-orange-50 to-red-50 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                                        <div className="h-12 w-12 bg-orange-500 rounded-lg mb-4 flex items-center justify-center">
                                            <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                            </svg>
                                        </div>
                                        <h3 className="text-xl font-semibold mb-4 text-orange-900">
                                            {language === 'en' ? 'Protocols' : 'البروتوكولات'}
                                        </h3>
                                        <p className="text-gray-700">
                                            {language === 'en'
                                                ? 'Protocols are the applications and platforms that operate on blockchains, offering services like banking, trading, and token exchanges. They form the backbone of decentralized finance.'
                                                : 'البروتوكولات هي التطبيقات والمنصات التي تعمل على البلوكتشين، وتقدم خدمات مثل الخدمات المصرفية والتداول وتبادل الرموز. إنها تشكل العمود الفقري للتمويل اللامركزي.'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* ROI Section */}
                        <section className="space-y-8 section-fade" style={{ animationDelay: "100ms" }}>
                            <div>
                                <h2 className="text-2xl font-semibold mb-4">{language === 'en' ? 'Exceptional ROI' : reportContentAr.airdrops.title}</h2>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                                    {reportContent.airdrops.map((airdrop, index) => (
                                        <AirdropCard
                                            key={airdrop.name}
                                            name={language === 'en' ? airdrop.name : reportContentAr.airdrops[index].name}
                                            value={airdrop.value}
                                            description={language === 'en' ? airdrop.description : reportContentAr.airdrops[index].description}
                                            status={airdrop.status}
                                            delay={200 + (index * 100)}
                                        />
                                    ))}
                                </div>
                                <ChartSection />
                            </div>
                        </section>

                        {/* Protocols Sections */}
                        {Object.entries(reportContent.protocols).map(([key, section], index) => (
                            <section key={key} className="space-y-8 section-fade" style={{ animationDelay: `${200 + (index * 100)}ms` }}>
                                <div>
                                    <h2 className="text-2xl text-center font-semibold mb-4">
                                        {language === 'en' ? section.title : reportContentAr.protocols[key].title}
                                    </h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {section.list.map((protocol, pIndex) => (
                                            <ProtocolCard
                                                key={protocol}
                                                name={language === 'en' ? protocol : reportContentAr.protocols[key]?.list?.[pIndex] || protocol}
                                                description={language === 'en' ? section.description : reportContentAr.protocols[key].description}
                                                chain={language === 'en' ? key.charAt(0).toUpperCase() + key.slice(1) : key}
                                                status="Active"
                                                delay={500 + (pIndex * 100)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </section>
                        ))}

                        {/* Summary */}
                        <section className="space-y-4 section-fade" style={{ animationDelay: "300ms" }}>
                            <div className="max-w-2xl mx-auto">
                                <h2 className={`text-2xl font-semibold mb-4 ${language === 'en' ? 'text-left' : 'text-right'}`}>
                                    {language === 'en' ? reportContent.summary.title : reportContentAr.summary.title}
                                </h2>
                                <div className="glass-card p-6 space-y-4">
                                    <ul className={`space-y-2 ${language === 'en' ? 'text-left' : 'text-right'}`}>
                                        {reportContent.summary.points.map((point, index) => (
                                            <li key={index} className={`text-muted-foreground flex items-start ${language === 'en' ? 'justify-start' : 'justify-end'}`}>
                                                {language === 'en' && <span className="mr-2">•</span>}
                                                {language === 'en' ? point : reportContentAr.summary.points[index]}
                                                {language !== 'en' && <span className="ml-2">•</span>}
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        className="w-full sm:w-auto hover:bg-accent hover:text-accent-foreground transition-colors"
                                        onClick={() => window.open('https://docs.google.com/document/d/1ey54mk-xk2X0d3dsRcTixgy0FBGS80SXQ5Yw_llhMFM/edit?tab=t.0#heading=h.hnp99gh7zlc', '_blank')}
                                    >
                                        {language === 'en' ? 'Learn More About Airdrop Farming' : 'تعرف على المزيد حول زراعة الإيردروب'}
                                        <ArrowUpRight className="ml-2 h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                        </section>
                    </>
                ) : (
                    <>
                        <div className="flex justify-center mt-2">
                            <div className="bg-gray-800/50 backdrop-blur-sm p-1 rounded-lg flex gap-1">
                                <button
                                    onClick={() => setActiveTab2('main')}
                                    className={`px-6 py-2.5 rounded-md font-medium transition-all duration-200 ${
                                        activeTab2 === 'main' 
                                        ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/30 hover:bg-blue-700' 
                                        : 'text-gray-300 hover:text-white hover:bg-gray-700/50'
                                    }`}
                                >
                                    Main
                                </button>
                                <button
                                    onClick={() => setActiveTab2('component')}
                                    className={`px-6 py-2.5 rounded-md font-medium transition-all duration-200 ${
                                        activeTab2 === 'component'
                                        ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/30 hover:bg-blue-700'
                                        : 'text-gray-300 hover:text-white hover:bg-gray-700/50'
                                    }`}
                                >
                                    Component
                                </button>
                            </div>
                        </div>

                        {activeTab2 === 'main' ? (
                            <div className="text-center mt-2">
                                <div className="glass-card p-6 space-y-8 bg-gradient-to-br from-gray-800 to-gray-700 backdrop-blur-lg rounded-xl shadow-2xl">
                                    {Object.entries(mainReportContent).map(([key, section]) => (
                                        <Card key={key} className="mb-12 hover:shadow-xl transition-all duration-300 bg-gray-700/50 border border-gray-600/50">
                                            <CardHeader className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-6">
                                                <CardTitle className="text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-purple-300 font-sans tracking-tight">
                                                    {language === 'en' ? section.title : mainReportContentAr?.[key]?.title}
                                                </CardTitle>
                                            </CardHeader>
                                            <CardContent className="p-6">
                                                {section.image && (
                                                    <div className="flex justify-center mb-6">
                                                        <img
                                                            src={section.image}
                                                            alt={section.title}
                                                            className="max-w-full h-auto rounded-lg shadow-lg hover:scale-[1.02] transition-transform duration-300"
                                                        />
                                                    </div>
                                                )}
                                                <div className={`whitespace-pre-line text-left text-gray-100 leading-relaxed font-light text-lg ${language === 'en' ? 'text-left' : 'text-right'}`}>
                                                    {language === 'en' ? section.description : mainReportContentAr?.[key]?.description}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>
                                <TrumpAdministration language={language} />
                                <div className="glass-card p-6 space-y-8 bg-gradient-to-br from-gray-800 to-gray-700 backdrop-blur-lg rounded-xl shadow-2xl">
                                    {Object.entries(mainReportContent2).map(([key, section]) => (
                                        <Card key={key} className="mb-12 hover:shadow-xl transition-all duration-300 bg-gray-700/50 border border-gray-600/50">
                                            <CardHeader className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-6">
                                                <CardTitle className="text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-purple-300 font-sans tracking-tight">
                                                    {language === 'en' ? section.title : mainReportContentAr2?.[key]?.title}
                                                </CardTitle>
                                            </CardHeader>
                                            <CardContent className="p-6">
                                                {section.image && (
                                                    <div className="flex justify-center mb-6">
                                                        <img
                                                            src={section.image}
                                                            alt={section.title}
                                                            className="max-w-full h-auto rounded-lg shadow-lg hover:scale-[1.02] transition-transform duration-300"
                                                        />
                                                    </div>
                                                )}
                                                <div className={`whitespace-pre-line text-left text-gray-100 leading-relaxed font-light text-lg ${language === 'en' ? 'text-left' : 'text-right'}`}>
                                                    {language === 'en' ? section.description : mainReportContentAr2?.[key]?.description}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="bg-background min-h-screen">
                                <div className="container mx-auto py-8 px-4">
                                    <ReportHeader language={language} />
                                    <MarketPerformance language={language} />
                                    <MacroeconomicChallenges language={language} />
                                    <TrumpAdministration language={language} />
                                    <PostHalvingDynamics language={language} />
                                    <RegulatoryEnvironment language={language} />
                                    <SpekulePerformance language={language} />
                                    <Conclusion language={language} />
                                    <Appendix language={language} />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </main>
        </div>
    );
};

export default Index;