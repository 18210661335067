export const reportContent = {
    introduction: {
      title: "Introduction to Airdrops",
      description: `Cryptocurrencies are digital assets, and Bitcoin is just one example. Many others exist, each serving a different purpose in the broader crypto ecosystem.
  
  When crypto-based companies have been around for a while, they usually launch their own cryptocurrency, which is called a token. This token is equivalent to a company creating its stock and listing it on the stock market.
  
  An airdrop is when a company gives out tokens to loyal users of its product(s). This distributes tokens into the market in a healthy way that is much more attractive to investors. Additionally, the airdrop itself attracts significant attention because people will be talking about it, giving the company organic marketing and thus more buyers.`,
      keyTerms: [
        {
          term: "Chains (Blockchains)",
          description: "Chains are like the highways of crypto; they enable transactions and power everything in the ecosystem. Layer 1 chains, such as Bitcoin or Ethereum, are the main highways, while Layer 2 chains, like Arbitrum, act as shortcuts to make things faster and cheaper."
        },
        {
          term: "Protocols",
          description: "Protocols are apps/programs/platforms that interact with a blockchain. This can be a bank-like platform or an exchange that allows you to buy/sell various tokens."
        }
      ]
    },
    airdrops: [
      {
        name: "$HYPE",
        value: "$200,000",
        description: "Hyperliquid rewarded early users with generous token allocations. Users with $10k investment saw allocations worth $100k-$200k.",
        status: "Completed"
      },
      {
        name: "$ARB",
        value: "$60,000",
        description: "With a $10k investment, participants received tokens worth $10k-$30k at launch, peaking at $20k-$60k.",
        status: "Completed"
      },
      {
        name: "$SCR",
        value: "$10,000",
        description: "Scroll offered $3k-$10k for a $10k investment. Required minimal effort, making it low-risk.",
        status: "Completed"
      }
    ],
    protocols: {
      base: {
        title: "Base Protocols",
        description: "Base is the product of Coinbase, with huge backing and growing Ethereum-based activity. Base has caught up to Arbitrum rapidly, now at 75% its size with 5x the users.",
        list: ["Aori", "Astaria", "Arcadia", "Avantis", "Galaxy", "Folks", "Infusion", "Definitive", "Starbase", "Virtual"]
      },
      solana: {
        title: "Solana Protocols",
        description: "Solana is a mature chain that grew over 10x in the past year. Its ecosystem is growing quickly as companies see value in building on Solana.",
        list: ["Stabble", "Jupiter", "RainFi", "Lulo", "Meteora", "Flash.Trade", "Adrastea Finance", "Phoenix", "DeFiTuna", "Fragmetric", "Perena", "Titan", "Lavarage", "Switchboard", "Astherus", "Invariant", "Jumper", "Ranger", "Mantis", "DEFINITIVE", "Galaxy"]
      },
      sui: {
        title: "Sui Protocols",
        description: "Sui grew over 10x and is a younger chain with great ecosystem upside. The primary benefit is being early, with less competition and more room for growth.",
        list: ["Aftermath", "insiDeX", "Bucket", "ABEx", "Typus", "7K", "EnsoFi", "AlphaFi", "Hop", "Sudo", "Balanced"]
      }
    },
    summary: {
      title: "Summary",
      points: [
        "Airdrops are free crypto tokens distributed as part of marketing efforts.",
        "Airdrop farming can offer exceptional returns (30-100%+) with minimal risk.",
        "Success depends on being early and meeting eligibility criteria.",
        "Key platforms include Base, Solana, and Sui.",
        "Diversification across protocols minimizes risk."
      ]
    }
  };
  export const reportContentAr = {
    introduction: {
      title: "مقدمة حول الإيردروب",
      description: `العملات الرقمية هي أصول رقمية، وبيتكوين هو مجرد مثال واحد. هناك العديد من العملات الأخرى التي تخدم أغراضًا مختلفة في النظام البيئي للعملات المشفرة.
  
  عندما تكون الشركات القائمة على العملات المشفرة موجودة لفترة من الوقت، فإنها عادةً ما تطلق عملتها الخاصة التي تُعرف بالتوكن. يشبه هذا إنشاء شركة لأسهمها وإدراجها في سوق الأسهم.
  
  الإيردروب هو عندما تقوم الشركة بتوزيع التوكنز على المستخدمين المخلصين لمنتجاتها. هذا يوزع التوكنز في السوق بطريقة صحية تكون جذابة جدًا للمستثمرين. بالإضافة إلى ذلك، فإن الإيردروب نفسه يجذب انتباهًا كبيرًا لأن الناس يتحدثون عنه، مما يمنح الشركة تسويقًا عضويًا وبالتالي مزيدًا من المشترين.`,
      keyTerms: [
        {
          term: "السلاسل (البلوكتشين)",
          description: "السلاسل هي مثل الطرق السريعة للعملات المشفرة؛ فهي تُمكن المعاملات وتدعم كل شيء في النظام البيئي. السلاسل من الطبقة الأولى، مثل بيتكوين أو إيثريوم، هي الطرق الرئيسية، بينما السلاسل من الطبقة الثانية، مثل أربتيروم، تعمل كاختصارات لجعل الأمور أسرع وأرخص."
        },
        {
          term: "البروتوكولات",
          description: "البروتوكولات هي تطبيقات/برامج/منصات تتفاعل مع البلوكتشين. يمكن أن تكون منصة مثل البنوك أو تبادل يتيح لك شراء/بيع التوكنز المختلفة."
        }
      ]
    },
    airdrops: [
      {
        name: "$HYPE",
        value: "$200,000",
        description: "هايبرليكود كافأت المستخدمين الأوائل بتخصيصات توكن سخية. المستخدمون الذين استثمروا $10,000 حصلوا على تخصيصات بقيمة $100,000-$200,000.",
        status: "مكتمل"
      },
      {
        name: "$ARB",
        value: "$60,000",
        description: "مع استثمار بقيمة $10,000، حصل المشاركون على توكنز بقيمة $10,000-$30,000 عند الإطلاق، وبلغت قيمتها ذروتها بين $20,000-$60,000.",
        status: "مكتمل"
      },
      {
        name: "$SCR",
        value: "$10,000",
        description: "سكروول قدمت $3,000-$10,000 لاستثمار بقيمة $10,000. تطلبت جهدًا قليلًا، مما جعلها مخاطرة منخفضة.",
        status: "مكتمل"
      }
    ],
    protocols: {
      base: {
        title: "بروتوكولات بيس",
        description: "بيس هو منتج من كوينبيس، بدعم كبير ونشاط متزايد على إيثريوم. بيس اقترب بسرعة من أربتيروم، حيث وصلت الآن إلى 75٪ من حجمها مع 5 أضعاف عدد المستخدمين.",
        list: ["Aori", "Astaria", "Arcadia", "Avantis", "Galaxy", "Folks", "Infusion", "Definitive", "Starbase", "Virtual"]
      },
      solana: {
        title: "بروتوكولات سولانا",
        description: "سولانا هي سلسلة ناضجة نمت أكثر من 10 أضعاف في العام الماضي. نظامها البيئي ينمو بسرعة حيث ترى الشركات قيمة في البناء على سولانا.",
        list: ["Stabble", "Jupiter", "RainFi", "Lulo", "Meteora", "Flash.Trade", "Adrastea Finance", "Phoenix", "DeFiTuna", "Fragmetric", "Perena", "Titan", "Lavarage", "Switchboard", "Astherus", "Invariant", "Jumper", "Ranger", "Mantis", "DEFINITIVE", "Galaxy"]
      },
      sui: {
        title: "بروتوكولات سوي",
        description: "سوي نمت أكثر من 10 أضعاف وهي سلسلة أحدث تتمتع بإمكانات نمو كبيرة للنظام البيئي. الفائدة الأساسية هي التواجد المبكر، مع وجود منافسة أقل ومجال أكبر للنمو.",
        list: ["Aftermath", "insiDeX", "Bucket", "ABEx", "Typus", "7K", "EnsoFi", "AlphaFi", "Hop", "Sudo", "Balanced"]
      }
    },
    summary: {
      title: "الملخص",
      points: [
        "الإيردروبات هي توكنز مشفرة مجانية يتم توزيعها كجزء من جهود التسويق.",
        "زراعة الإيردروبات يمكن أن تقدم عوائد استثنائية (30-100٪+) مع مخاطر قليلة.",
        "يعتمد النجاح على التواجد المبكر واستيفاء معايير الأهلية.",
        "المنصات الرئيسية تشمل بيس، سولانا، وسوي.",
        "التنويع عبر البروتوكولات يقلل من المخاطر."
      ]
    }
  };
  
  export const wordFileContent = [
    {
        title: "Introduction",
        points: [
            "Overview of the cryptocurrency market in 2024, highlighting key developments and trends shaping the industry.",
            "Contextualization of market performance, macroeconomic factors, and regulatory shifts."
        ]
    },
    {
        title: "Market Performance in 2024",
        points: [
            "Bitcoin’s dominance reaching 60% of the cryptocurrency market cap.",
            "Institutional adoption through Spot Bitcoin ETFs by BlackRock, Fidelity, and Ark.",
            "Political recognition with pro-crypto stances during U.S. presidential elections.",
            "Introduction of a Bitcoin strategic reserve proposal in the U.S. Senate.",
            "Delayed altcoin rally and its implications for the cryptocurrency market cycle."
        ]
    },
    {
        title: "Factors Driving Bitcoin Dominance",
        points: [
            "Increased market share attributed to Spot Bitcoin ETF filings.",
            "Reallocation of funds by investors from altcoins to Bitcoin.",
            "Institutional interest and Bitcoin's safe-haven asset narrative.",
            "Insights on market trends and macroeconomic environment."
        ]
    },
    {
        title: "Altcoins and the Market Cycle",
        points: [
            "Current status of altcoins with diminished performance relative to Bitcoin.",
            "Anticipation of an altcoin rally driven by major network upgrades and use cases.",
            "The role of DeFi, gaming, and tokenization in driving future altcoin performance."
        ]
    },
    {
        title: "Challenges Facing the Industry",
        points: [
            "Regulatory uncertainty and its impact on innovation.",
            "Global economic conditions influencing cryptocurrency adoption.",
            "Technological hurdles in scaling blockchain networks for mainstream use."
        ]
    },
    {
        title: "Future Projections",
        points: [
            "Potential for cryptocurrency integration into traditional finance.",
            "The rise of central bank digital currencies (CBDCs) and their implications.",
            "Exploration of potential bull and bear scenarios for the market in 2025."
        ]
    }
];

  export const wordFileContentAr = [
      {
          title: "مقدمة",
          points: [
              "نظرة عامة على سوق العملات المشفرة في عام 2024، مع تسليط الضوء على التطورات الرئيسية والاتجاهات التي تشكل الصناعة.",
              "تأطير أداء السوق والعوامل الاقتصادية الكلية والتحولات التنظيمية."
          ]
      },
      {
          title: "أداء السوق في عام 2024",
          points: [
              "سيطرة البيتكوين على 60% من القيمة السوقية للعملات المشفرة.",
              "تبني مؤسسي عبر صناديق Bitcoin ETFs بواسطة BlackRock وFidelity وArk.",
              "الاعتراف السياسي بمواقف داعمة للعملات المشفرة خلال الانتخابات الرئاسية الأمريكية.",
              "اقتراح إنشاء احتياطي استراتيجي للبيتكوين في مجلس الشيوخ الأمريكي.",
              "تأخر انتعاش العملات البديلة وتأثيراته على دورة سوق العملات المشفرة."
          ]
      },
      {
          title: "عوامل تعزيز سيطرة البيتكوين",
          points: [
              "زيادة الحصة السوقية نتيجة تقديم طلبات صناديق Bitcoin ETFs.",
              "إعادة تخصيص المستثمرين أموالهم من العملات البديلة إلى البيتكوين.",
              "اهتمام مؤسسي وسرد البيتكوين كأصل آمن.",
              "رؤى حول اتجاهات السوق والبيئة الاقتصادية الكلية."
          ]
      },
      {
          title: "العملات البديلة ودورة السوق",
          points: [
              "الوضع الحالي للعملات البديلة مع الأداء الضعيف مقارنة بالبيتكوين.",
              "توقع انتعاش العملات البديلة نتيجة ترقية الشبكات الرئيسية وحالات الاستخدام.",
              "دور التمويل اللامركزي والألعاب والرموز المميزة في تعزيز أداء العملات البديلة في المستقبل."
          ]
      },
      {
          title: "التحديات التي تواجه الصناعة",
          points: [
              "عدم اليقين التنظيمي وتأثيره على الابتكار.",
              "الظروف الاقتصادية العالمية وتأثيرها على اعتماد العملات المشفرة.",
              "العقبات التكنولوجية في توسيع شبكات البلوكشين للاستخدام السائد."
          ]
      },
      {
          title: "توقعات المستقبل",
          points: [
              "إمكانية دمج العملات المشفرة في التمويل التقليدي.",
              "صعود العملات الرقمية للبنوك المركزية (CBDCs) وتأثيراتها.",
              "استكشاف السيناريوهات المحتملة للسوق بين الصعود والهبوط في عام 2025."
          ]
      }
  ];

    