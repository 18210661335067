import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const MacroeconomicChallenges = ({ language }) => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? 'Macroeconomic Challenges' : 'التحديات الاقتصادية الكلية'}
        </CardTitle>
      </CardHeader>
      <CardContent>
      <div className="flex justify-center mb-8">
          <img
            src="/fredChart.png"
            alt={language === 'en' ? 'Macroeconomic Chart' : 'الرسم البياني الاقتصادي الكلي'}
            className="max-w-full h-auto"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-red-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-red-800">
              {language === 'en' ? 'US Debt-to-GDP Concerns' : 'مخاوف الدين إلى الناتج المحلي الإجمالي في الولايات المتحدة'}
            </h3>
            <p className="text-gray-700">
              {language === 'en'
                ? 'The US Debt-to-GDP ratio stands at 120.73%, presenting significant challenges to the global economy. This elevated level signals potential risks in fulfilling financial obligations.'
                : 'نسبة الدين إلى الناتج المحلي الإجمالي في الولايات المتحدة تبلغ 120.73٪، مما يشكل تحديات كبيرة للاقتصاد العالمي. هذا المستوى المرتفع يشير إلى مخاطر محتملة في الوفاء بالالتزامات المالية.'}
            </p>
            <div className="mt-4 p-4 bg-black rounded-md">
              <h4 className="font-medium mb-2">
                {language === 'en' ? 'Key Solutions:' : 'الحلول الرئيسية:'}
              </h4>
              <ul className="list-disc pl-4 space-y-2">
                <li>{language === 'en' ? 'Innovation and Development' : 'الابتكار والتطوير'}</li>
                <li>{language === 'en' ? 'Monetary Expansion' : 'التوسع النقدي'}</li>
              </ul>
            </div>
          </div>
          <div className="bg-blue-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-blue-800">
              {language === 'en' ? 'Trump Administration Approach' : 'نهج إدارة ترامب'}
            </h3>
            <p className="text-gray-700">
              {language === 'en'
                ? 'A technocratic approach to governance, led by accomplished business leaders, aims to treat America as a business and drive innovation.'
                : 'نهج تقني في الحكم، بقيادة قادة أعمال بارعين، يهدف إلى معاملة أمريكا كعمل تجاري ودفع الابتكار.'}
            </p>
            <div className="mt-4 p-4 bg-black rounded-md">
              <h4 className="font-medium mb-2">
                {language === 'en' ? 'Key Initiatives:' : 'المبادرات الرئيسية:'}
              </h4>
              <ul className="list-disc pl-4 space-y-2">
                <li>{language === 'en' ? 'D.O.G.E. Department Formation' : 'تشكيل قسم D.O.G.E.'}</li>
                <li>{language === 'en' ? 'Crypto & AI Innovation Focus' : 'التركيز على الابتكار في العملات المشفرة والذكاء الاصطناعي'}</li>
              </ul>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MacroeconomicChallenges;