// client/src/context/UserContext.js
import React, { createContext, useState, useContext } from 'react';
const UserContext = createContext();

export const useUser = () => useContext(UserContext);
export const UserProvider = ({ children }) => {

    const [user, setUser] = useState({
        username: '',
        role: 'normal', // Add role here
        access_token: ''
      });
    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export async function setUserContext(setUser, userData) {

    // Use setUser here
    setUser({
      username: userData.username,
      role: userData.role,
      access_token: userData.accessToken,
      email: userData.email
    });
    console.log("userData in setUserContext", userData);
  }
