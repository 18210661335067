import React from 'react';
import { CoinGeckoComponent } from './tradingComponents/CoinGeckocomponent';
import { Sidebar } from './Sidebar';
import { TopNav } from './top-nav';
import Navigation from './ClientPortalComponents/ui/navigation';
import { useUser } from '../context/UserContext';

function CoingeckoPage() {
  const { user } = useUser();
  const UserRole = user.role;
  if (UserRole === 'normal') {
    return (
      <div className="flex h-screen bg-background">
        <Sidebar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <TopNav />
          <main className="flex-1 overflow-y-auto">
            <div className="w-full">
              <CoinGeckoComponent />
            </div>
          </main>
        </div>
      </div>
    );
  } else if (UserRole === 'client') {
    return (
      <div className="flex flex-col flex-1 overflow-hidden">
        <Navigation />
        <main className="flex-1 overflow-y-auto p-8">
          <div className="w-full max-w-7xl mx-auto bg-gray-800 p-6 rounded-lg shadow-lg mt-16">
            <h2 className="text-2xl font-bold mb-4">Altimeter: Crypto Heights & Fibonacci Depths</h2>
            <div className="overflow-x-auto">
              <CoinGeckoComponent />
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default CoingeckoPage;