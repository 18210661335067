import React from "react";
import { Button } from "./Button";
import { useState } from "react";
import { Menu, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="fixed w-full z-50 top-0 left-0 px-6 py-4">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a href="/" className="flex items-center space-x-3">
              <div className="relative w-12 h-12">
                <img
                  src="/logoSpekuleTransparent.png"
                  alt="Spekule"
                  className="w-full h-full object-contain"
                />
              </div>
              <span className="text-2xl font-clash font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
                Spekule
              </span>
            </a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="/documentation" className="text-gray-300 hover:text-white transition-colors">
              Documentation
            </a>
            <a href="/about" className="text-gray-300 hover:text-white transition-colors">
              About
            </a>
            <Button
              className="bg-gradient-to-r from-[#8E44AD] to-[#FF1493] hover:opacity-90 text-white px-8 py-3 rounded-full text-lg"
              onClick={() => navigate('/login')}
            >
              Sign In
            </Button>
          </div>

          {/* Mobile Navigation Button */}
          <div className="md:hidden">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setIsOpen(!isOpen)}
              className="text-white"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </Button>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {isOpen && (
          <div className="md:hidden absolute top-full left-0 w-full glass-panel mt-2 py-4">
            <div className="flex flex-col space-y-4 px-6">
              <a href="/documentation" className="text-gray-300 hover:text-white transition-colors">
                Documentation
              </a>
              <a href="/about" className="text-gray-300 hover:text-white transition-colors">
                About
              </a>
              <Button 
                className="bg-zinc-800 hover:bg-zinc-700 text-white w-full"
                onClick={() => navigate('/login')}
              >
                Sign In
              </Button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};