// client/src/components/ui/Badge.js

import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ text, color }) => {
    const badgeStyle = {
        display: 'inline-block',
        padding: '0.25em 0.4em',
        fontSize: '75%',
        fontWeight: '700',
        lineHeight: '1',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        borderRadius: '0.25rem',
        color: '#fff',
        backgroundColor: color || '#007bff',
    };

    return <span style={badgeStyle}>{text}</span>;
};

Badge.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
};

export default Badge;