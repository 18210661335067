import { Card } from "../ui/card";
import Badge from "../ui/Badge";
import PropTypes from 'prop-types';

export const AirdropCard = ({
  name,
  value, 
  description,
  status,
  delay = 0,
}) => {
  return (
    <Card
      className="p-6 glass-card hover-scale section-fade"
      style={{
        animationDelay: `${delay}ms`,
      }}
    >
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-semibold">{name}</h3>
        <Badge text={status} color={status === 'Completed' ? '#6B7280' : '#6B7280'} />

      </div>
      <p className="text-3xl font-bold mb-4">{value}</p>
      <p className="text-muted-foreground text-sm">{description}</p>
    </Card>
  );
};

AirdropCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['Completed', 'Upcoming']).isRequired,
  delay: PropTypes.number
};