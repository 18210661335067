import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';
import OmegaChart from './omegaChart';
import VolatilityChart from './VolatilityChart';
import SharpRatioChart from './SharpRatioChart';
import SortinoChart from './SortinoChart';

const MetricsChartsComponent = ({ omegaData, volatilityData, sharpRatioData, sortinoData }) => {
  const [selectedChart, setSelectedChart] = useState('omega');

  return (
    <Card className="w-full mt-6 border border-transparent">
      <CardHeader className="text-center font-bold">
        <CardTitle>Key Metrics Histogram</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-center space-x-4 mb-4">
          <button
            onClick={() => setSelectedChart('omega')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedChart === 'omega' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Omega
          </button>
          <button
            onClick={() => setSelectedChart('volatility')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedChart === 'volatility' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Volatility
          </button>
          <button
            onClick={() => setSelectedChart('sharpRatio')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedChart === 'sharpRatio' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Sharpe Ratio
          </button>
          <button
            onClick={() => setSelectedChart('sortino')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedChart === 'sortino' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Sortino
          </button>
        </div>
        <div className="w-full">
          {selectedChart === 'omega' && <OmegaChart omegaData={omegaData} />}
          {selectedChart === 'volatility' && <VolatilityChart volatilityData={volatilityData} />}
          {selectedChart === 'sharpRatio' && <SharpRatioChart sharpRatioData={sharpRatioData} />}
          {selectedChart === 'sortino' && <SortinoChart sortinoData={sortinoData} />}
        </div>
      </CardContent>
    </Card>
  );
};

export default MetricsChartsComponent;