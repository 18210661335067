import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { executeSwap } from '../../services/api';
import { toast } from 'react-toastify';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Select, MenuItem, Button, Box } from '@mui/material';
const exchanges = ['binance', 'bybit', 'bitget', 'kucoin'];
export function SwapComponent() {
  const { user } = useUser();
  const [notifications, setNotifications] = useState([]);
  const [exchange, setExchange] = useState('');
  const [token, setToken] = useState('');
  const [amount, setAmount] = useState('');
  const [limitPrice, setLimitPrice] = useState('');
  const [action, setAction] = useState('');
  const [error, setError] = useState(null);

  const handleSwap = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const swapData = {
      username: user.username,
      exchange,
      token,
      amount,
      action,
      limitPrice: limitPrice || null,
    };
    try {
      const result = await executeSwap(swapData);
      if (result.success) {
        alert(`${action.charAt(0).toUpperCase() + action.slice(1)} executed successfully`);
        toast.success('Trade executed successfully!');
        setNotifications([...notifications, { message: 'Trade executed successfully', time: new Date().toLocaleTimeString() }]);
        setError(null);
      } else {
        const errorMessage = result.message || `Failed to execute ${action}`;
        alert(errorMessage);
        toast.error(errorMessage);
        setError(errorMessage);
      }
    } catch (error) {
      const errorMessage = `Error: ${error.message}`;
      toast.error(errorMessage);
      setError(errorMessage);
    }
  };



  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: 'transparent',
        padding: 2,
      }}
    >
      <form className="space-y-4" onSubmit={handleSwap}>
        <div className="space-y-2">
          <Label htmlFor="exchange" className="text-xs">Exchange</Label>
          <Select
            value={exchange}
            onChange={(e) => setExchange(e.target.value)}
            fullWidth
            displayEmpty
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: '#fff',
              '& .MuiSelect-icon': { color: '#fff' },
            }}
          >
            <MenuItem value="" disabled>Select an exchange</MenuItem>
            {exchanges.map((ex) => (
              <MenuItem key={ex} value={ex}>
                {ex}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="space-y-2">
          <Label htmlFor="token" className="text-xs">Symbol</Label>
          <Input
            id="token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="amount" className="text-xs">Amount</Label>
          <Input
            id="amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="limitPrice" className="text-xs">Limit Price</Label>
          <Input
            id="limitPrice"
            type="number"
            value={limitPrice}
            onChange={(e) => setLimitPrice(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <Button
            type="submit"
            className="w-full"
            style={{ backgroundColor: '#00e676', color: '#000000', height: '48px', '&:hover': { backgroundColor: '#00c853' } }}
            onClick={() => setAction('buy')}
          >
            Buy
          </Button>
        </div>
        <div className="space-y-2">
          <Button
            type="submit"
            className="w-full"
            style={{ backgroundColor: '#ff1744', color: '#ffffff', height: '48px', '&:hover': { backgroundColor: '#d50000' } }}
            onClick={() => setAction('sell')}
          >
            Sell
          </Button>
        </div>
        {error && (
          <div
            className="text-red-500 text-xs mt-2 border border-red-500 p-2 rounded"
            style={{ textAlign: 'center', fontWeight: 'bold' }}
          >
            {(() => {
              try {
                const parsedError = JSON.parse(error);
                return parsedError.message || error;
              } catch {
                return error;
              }
            })()}
          </div>
        )}      
      </form>
    
    </Box>
  );



}
