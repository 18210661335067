import { Card } from "../ui/card";
import  Badge  from "../ui/Badge";

// Using PropTypes instead of TypeScript interface since this is a .js file
import PropTypes from 'prop-types';

export const ProtocolCard = ({
  name,
  description,
  chain,
  status,
  delay = 0,
}) => {
  return (
    <Card
      className="p-6 glass-card hover-scale"
      style={{
        animationDelay: `${delay}ms`,
      }}
    >
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-semibold">{name}</h3>
        <Badge text={status} color={status === 'Active' ? '#10B981' : '#6B7280'} />
      </div>
      <p className="text-muted-foreground text-sm mb-4">{description}</p>
      <Badge variant="outline" className="text-xs">
        {chain}
      </Badge>
    </Card>
  );
};

ProtocolCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  chain: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['Active', 'Coming Soon']).isRequired,
  delay: PropTypes.number
};