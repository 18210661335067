import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";

const ReportHeader = ({ language }) => {
  return (
    <Card className="w-full mb-8 bg-gradient-to-r from-blue-600 to-purple-600">
      <CardHeader className="text-white">
        <CardTitle className="text-4xl font-bold mb-4">
          {language === 'en' ? 'Bitcoin Synthesis Report 2024' : 'تقرير تحليل البيتكوين 2024'}
        </CardTitle>
        <CardDescription className="text-gray-100 text-lg">
          {language === 'en' ? 'A comprehensive analysis of the cryptocurrency market' : 'تحليل شامل لسوق العملات الرقمية'}
        </CardDescription>
      </CardHeader>
      <CardContent className="text-gray-100">
        <p className="mb-4">
          {language === 'en'
            ? '2024 was the year Bitcoin set the standard. Reaching 60% of the total cryptocurrency market cap, Bitcoin reasserted its leadership, driving market momentum and setting the pace for a year defined by strategic moves and transformative opportunities.'
            : 'كان عام 2024 هو العام الذي وضع فيه البيتكوين المعيار. حيث وصل إلى 60٪ من إجمالي القيمة السوقية للعملات الرقمية، وأعاد البيتكوين تأكيد قيادته، مما دفع السوق وحدد وتيرة عام يتميز بالتحركات الاستراتيجية والفرص المحورية.'}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
          <div className="bg-white/10 p-4 rounded-lg">
            <h3 className="font-semibold mb-2">
              {language === 'en' ? 'Market Trends' : 'اتجاهات السوق'}
            </h3>
            <p>
              {language === 'en' ? "Analysis of Bitcoin's post-halving performance and future implications" : 'تحليل أداء البيتكوين بعد التنصف وآثاره المستقبلية'}
            </p>
          </div>
          <div className="bg-white/10 p-4 rounded-lg">
            <h3 className="font-semibold mb-2">
              {language === 'en' ? 'Macro Challenges' : 'التحديات الاقتصادية الكلية'}
            </h3>
            <p>
              {language === 'en' ? 'U.S. debt-to-GDP concerns and their impact on markets' : 'مخاوف الدين إلى الناتج المحلي الإجمالي في الولايات المتحدة وتأثيرها على الأسواق'}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ReportHeader;