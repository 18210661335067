import React from 'react'; // Corrected the import statement to use 'React' with a capital 'R'
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

export const SpaceBackground = () => {
  const [dots, setDots] = useState([]);

  useEffect(() => {
    const generateDots = () => {
      const numberOfDots = 50;
      const newDots = [];
      
      for (let i = 0; i < numberOfDots; i++) {
        newDots.push({
          x: Math.random() * 100,
          y: Math.random() * 100,
          size: Math.random() * 3 + 1,
        });
      }
      
      setDots(newDots);
    };

    generateDots();
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {dots.map((dot, index) => (
        <motion.div
          key={index}
          className="absolute rounded-full bg-white/30 backdrop-blur-sm"
          style={{
            left: `${dot.x}%`,
            top: `${dot.y}%`,
            width: `${dot.size}px`,
            height: `${dot.size}px`,
          }}
          animate={{
            y: [0, -30, 0],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: Math.random() * 3 + 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      ))}
    </div>
  );
};