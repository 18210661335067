// client/src/components/auth/LoginForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, setUserContext } from '../../context/UserContext';
import './LoginRegisterForm.css';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/card';
import Input from '@mui/material/Input';
import { Label } from '../ui/label';
import { Button } from '../ui/Button';
import { User, Lock, Eye, EyeOff } from 'lucide-react';
import InputAdornment from '@mui/material/InputAdornment';
import { loginWithSupabase, isUsernameTaken, isEmailTaken } from '../../services/api';
import { googleLogin } from '../../services/clientApi';

export function LoginFormComponent() {
  const { setUser } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // Check if there's a session token in localStorage
    console.log("rememberMe", rememberMe);
    const sessionToken = localStorage.getItem('sessionToken');
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    const storedEmail = localStorage.getItem('email');
    console.log("sessionToken", sessionToken);
    console.log("username", username);
    console.log("role", role);
    if (localStorage.getItem('rememberMe') === 'true') {
      console.log("rememberMe is true");
      setUserContext(setUser, {
        username: username,
        role: role,
        access_token: sessionToken,
        email: storedEmail
      });
      navigate(role === 'client' ? '/client-dashboard' : '/trading/dashboard');
    }
  }, [navigate, setUser]);

  const GoogleIcon = ({ className = "" }) => (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <path
        fill="#FFC107"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      />
      <path
        fill="#FF3D00"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      />
      <path
        fill="#4CAF50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      />
      <path
        fill="#1976D2"
        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      />
    </svg>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    let data;
    const isEmail = email.includes('@');
    if (isEmail) {
      const isEmailTakenResult = await isEmailTaken(email);
      if (!isEmailTakenResult.success) {
        setErrorMessage('Email is not registered');
        return;
      }
    } else {
      const isUsernameTakenResult = await isUsernameTaken(email);
      if (!isUsernameTakenResult.success) {
        setErrorMessage('Username is not registered');
        return;
      }
    }

    data = await loginWithSupabase(email, password);
    if (data && data.success === true) {
      console.log("data inside handleSubmit in loginFormComponent", data);
      setUserContext(setUser, { username: data.username, role: data.role, access_token: data.access_token, email: data.email });

      localStorage.setItem('sessionToken', data.access_token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('role', data.role);
      localStorage.setItem('email', data.email);
      console.log('data inside handleSubmit in loginFormComponent', data);
      if (data.role === 'client') {
        navigate('/client-dashboard');
      } else {
        navigate('/trading/dashboard');
      }
    } else {
      setErrorMessage(data.message || 'Login failed');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
    console.log("rememberMe in handleRememberMeChange", rememberMe);
    localStorage.setItem('rememberMe', e.target.checked);
  };
  console.log("rememberMe outside handleRememberMeChange", rememberMe);
  async function handleGoogleLoginResponse() {
    try {
      const response = await googleLogin();
      if (response.success) {
        openUrl(response.redirectUrl);
      } else {
        alert('Failed to redirect to Google for authentication.');
      }
    } catch (error) {
      console.error('Google login error:', error);
      alert('An error occurred during Google login.');
    }
  }

  function openUrl(url) {
    if (url) {
      window.open(url, '_blank');
    } else {
      alert('Failed to redirect to Google for authentication.');
    }
  }

  // function handleForgotPassword() {
  //   navigate('/forgot-password');
  // }

  return (
    <Card className="w-full max-w-md bg-black/50 backdrop-blur-md border-gray-800">
      <CardHeader className="space-y-1 flex flex-col items-center">
        <div className="relative w-32 h-32 mb-4">
          <img
            src="/logoSpekuleTransparent.png"
            alt="Crypto Trading Terminal Logo"
            fill
            className="object-contain w-full h-full"
          />
        </div>
        <p className="text-gray-400">Enter your credentials to access your account</p>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          <div>
            <Label htmlFor="email" className="text-gray-200">Email or Username </Label>
            <Input
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent text-white border-none w-full"
              startAdornment={
                <InputAdornment position="start">
                  <User className="text-white" size={18} />
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="password" className="text-gray-200">Password</Label>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Lock className="text-white" size={18} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeOff className="text-gray-400" size={18} />
                    ) : (
                      <Eye className="text-gray-400" size={18} />
                    )}
                  </Button>
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleRememberMeChange}
                className="mr-2"
              />
              <Label htmlFor="rememberMe" className="text-gray-200">Remember Me</Label>
            </div>
            {/* <button
              type="button"
              onClick={handleForgotPassword}
              className="text-blue-400 hover:underline text-sm"
            >
              Forgot Password?
            </button> */}
          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white">
            Sign In
          </Button>
          <Button
            type="button"
            className="
            w-full relative bg-white hover:bg-gray-100 text-gray-800 
            transition-all duration-300 ease-in-out
            flex items-center justify-center gap-3 py-3
            border border-gray-300 hover:border-gray-400
            shadow-sm hover:shadow-md"
            onClick={handleGoogleLoginResponse}
          >
            <GoogleIcon className="w-5 h-5" />
            <span className="text-[15px] font-medium">Continue with Google</span>
          </Button>
          {errorMessage && <div className="text-red-500 text-center">{errorMessage}</div>}
          <div className="text-sm text-gray-400 text-center">
            First time to Spekule?{' '}
            <a href="/register" className="text-blue-400 hover:underline">
              Sign up
            </a>
          </div>
        </CardFooter>
      </form>
    </Card>
  );
}
// hello
// export default LoginForm;
