// client/src/components/UnauthorizedPage.js
import React from 'react';
import { ShieldX } from 'lucide-react';
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
  return (
    <div className="min-h-screen bg-spekule-background flex flex-col items-center justify-center p-4">
      <div className="bg-spekule-card/30 backdrop-blur-lg rounded-xl p-8 max-w-md w-full border border-white/10 shadow-2xl">
        <div className="flex flex-col items-center text-center space-y-6">
          {/* Icon */}
          <div className="bg-red-500/10 p-4 rounded-full">
            <ShieldX className="w-12 h-12 text-red-500" />
          </div>
          
          {/* Title */}
          <h1 className="text-3xl font-montserrat-bold text-white">
            Unauthorized Access
          </h1>
          
          {/* Message */}
          <p className="text-gray-400 font-montserrat-medium">
            You don't have permission to view this page. Please make sure you're logged in with the correct credentials.
          </p>
          
          {/* Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 w-full pt-4">
            <Link
              to="/login"
              className="flex-1 bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg font-montserrat-medium transition-colors duration-200 text-center"
            >
              Login
            </Link>
            <Link
              to="/"
              className="flex-1 bg-gray-700 hover:bg-gray-600 text-white py-3 px-6 rounded-lg font-montserrat-medium transition-colors duration-200 text-center"
            >
              Go Home
            </Link>
          </div>
        </div>
      </div>
      
      {/* Background decoration */}
      <div className="fixed inset-0 -z-10 overflow-hidden">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-blue-500/5 rounded-full blur-3xl"></div>
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-red-500/5 rounded-full blur-3xl"></div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;