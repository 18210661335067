import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';

function transformData(data) {
  if (!Array.isArray(data) || data.length === 0) {
    console.error('Data is not iterable or is empty:', data);
    return [];
  }
  const [headers, ...rows] = data;
  return rows.map(row => {
    const entry = {};
    headers.forEach((header, index) => {
      entry[header] = row[index];
    });
    return entry;
  });
}

const ReturnsChart = ({ returnsData }) => {
  const [transformedData, setTransformedData] = useState([]);
  const [disabledLines, setDisabledLines] = useState({});

  useEffect(() => {
    if (returnsData && returnsData.length > 0) {
      const data = transformData(returnsData);
      setTransformedData(data);
    } else {
      setTransformedData([]);
    }
  }, [returnsData]);

  const handleLegendClick = (dataKey) => {
    setDisabledLines(prevState => ({
      ...prevState,
      [dataKey]: !prevState[dataKey]
    }));
  };

  const spekuleTextStyle = {
    color: '#00d1b2',
    fontWeight: 'bold',
    fontSize: '1.2em',
    opacity: disabledLines['Spekule'] ? 0.5 : 1
  };

  const renderTooltip = ({ payload, label }) => {
    if (!payload || payload.length === 0) return null;

    const sortedPayload = payload.sort((a, b) => (a.name === 'Spekule' ? -1 : 1));
    return (
      <div style={{ 
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '10px', 
        border: '1px solid #ccc',
        textAlign: 'center'
      }}>
        <p style={{ color: '#fff', fontWeight: 'bold' }}>{format(new Date(label), 'yyyy-MM-dd')}</p>
        {sortedPayload.map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{
              color: entry.name === 'Spekule' ? '#00d1b2' : entry.color,
              fontWeight: 'bold',
              opacity: 1,
              margin: '2px 0'
            }}
          >
            {entry.name} : {entry.value}
          </p>
        ))}
      </div>
    );
  };

  return (
    <Card className="col-span-3">
      <CardHeader>
        <CardTitle>Returns Data</CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart 
            data={transformedData}
            style={{background: 'transparent'}}
          >
            <XAxis 
              dataKey="Date"
              tickFormatter={(date) => format(new Date(date), 'MMM dd, yyyy')}
              stroke="#666"
              tick={{fill: '#999'}}
            />                        
            <YAxis stroke="#666" tick={{fill: '#999'}} />
            <Tooltip content={renderTooltip} />
            <Legend 
              onClick={(e) => handleLegendClick(e.dataKey)} 
              formatter={(value) => (
                <span style={value === 'Spekule' ? spekuleTextStyle : { opacity: disabledLines[value] ? 0.5 : 1, color: '#fff' }}>
                  {value}
                </span>
              )}
            />
            <Line type="linear" dataKey="Spekule" stroke="#00d1b2" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['Spekule'] ? 0.2 : 1} />
            <Line type="linear" dataKey="S&P 500" stroke="#FF0000" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['S&P 500'] ? 0.2 : 1} />
            <Line type="linear" dataKey="BTCUSD" stroke="#FFA500" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['BTCUSD'] ? 0.2 : 1} />
            <Line type="linear" dataKey="ETHUSD" stroke="#0000FF" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['ETHUSD'] ? 0.2 : 1} />
            <Line type="linear" dataKey="Gold" stroke="#FFD700" strokeWidth={2} dot={false} activeDot={false} strokeOpacity={disabledLines['Gold'] ? 0.2 : 1} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default ReturnsChart;