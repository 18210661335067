import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const Appendix = ({ language }) => {
  const definitions = [
    {
      term: language === 'en' ? 'Majors' : 'العملات الرئيسية',
      definition: language === 'en'
        ? 'The top three cryptocurrencies: Bitcoin, Ethereum, and Solana.'
        : 'أهم ثلاث عملات مشفرة: بيتكوين، إيثريوم، وسولانا.',
    },
    {
      term: language === 'en' ? 'Alts / Altcoins' : 'العملات البديلة',
      definition: language === 'en'
        ? 'All cryptocurrencies not classified as majors.'
        : 'جميع العملات المشفرة التي لا تصنف كعملات رئيسية.',
    },
    {
      term: language === 'en' ? 'FOMO' : 'الخوف من التفويت',
      definition: language === 'en'
        ? 'Fear of Missing Out - psychological phenomenon leading to impulsive investment decisions.'
        : 'الخوف من التفويت - ظاهرة نفسية تؤدي إلى قرارات استثمارية متسرعة.',
    },
    {
      term: language === 'en' ? 'ETFs' : 'الصناديق المتداولة في البورصة',
      definition: language === 'en'
        ? 'Exchange-Traded Funds tracking crypto assets, allowing indirect investment exposure.'
        : 'الصناديق المتداولة في البورصة التي تتبع الأصول المشفرة، مما يسمح بالتعرض الاستثماري غير المباشر.',
    },
    {
      term: language === 'en' ? 'Halving' : 'التنصيف',
      definition: language === 'en'
        ? 'Bitcoin network event reducing mining rewards by half every four years.'
        : 'حدث في شبكة البيتكوين يقلل مكافآت التعدين إلى النصف كل أربع سنوات.',
    },
  ];

  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? 'Appendix: Key Definitions' : 'الملحق: التعريفات الرئيسية'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {definitions.map((item, index) => (
            <div key={index} className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">{item.term}</h3>
              <p className="text-gray-700">{item.definition}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default Appendix;