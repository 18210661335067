import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/card';
import { Button } from '../ui/Button';
import Input from '@mui/material/Input';
import { Label } from '../ui/label';
import { sendingResetPassword , isEmailTaken} from '../../services/api';

export function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await isEmailTaken(email);
      if (response.success) {
        await sendingResetPassword(email);
        setMessage('If this email is registered, you will receive a password reset link.');
      } else {
        setMessage('This email is not registered.');
      }
    } catch (error) {
      console.error('Error sending reset password email:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <Card className="w-full max-w-md bg-black/50 backdrop-blur-md border-gray-800">
      <CardHeader className="space-y-1 flex flex-col items-center">
        <h2 className="text-gray-400">Forgot Password</h2>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          <div>
            <Label htmlFor="email" className="text-gray-200">Email Address</Label>
            <Input
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent text-white border-none w-full"
              sx={{
                color: 'white',
              }}
            />
          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white">
            Continue
          </Button>
          {message && <div className="text-green-500 text-center">{message}</div>}
        </CardFooter>
      </form>
    </Card>
  );
} 