import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const RegulatoryEnvironment = ({ language }) => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? 'Regulatory Environment' : 'البيئة التنظيمية'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="bg-green-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-green-800">
              {language === 'en' ? 'Policy Direction Shift' : 'تحول اتجاه السياسة'}
            </h3>
            <p className="text-green-900">
              {language === 'en'
                ? 'The appointment of Paul Atkins as SEC chair signals a transition from restrictive enforcement to innovation-friendly regulation, promising to dismantle barriers and enable projects to explore their full potential.'
                : 'يشير تعيين بول أتكينز كرئيس للجنة الأوراق المالية والبورصات إلى انتقال من التنفيذ التقييدي إلى التنظيم الداعم للابتكار، مما يعد بتفكيك الحواجز وتمكين المشاريع من استكشاف إمكاناتها الكاملة.'}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4 text-blue-800">
                {language === 'en' ? 'ICO Evolution' : 'تطور الطرح الأولي للعملات'}
              </h3>
              <p className="text-blue-900">
                {language === 'en'
                  ? 'Despite regulatory challenges, the ICO model has demonstrated resilience, enabling projects like Ethereum to revolutionize blockchain fundraising and development.'
                  : 'على الرغم من التحديات التنظيمية، أثبت نموذج الطرح الأولي للعملات مرونته، مما مكن مشاريع مثل إيثريوم من إحداث ثورة في جمع التبرعات وتطوير البلوكشين.'}
              </p>
            </div>
            <div className="bg-purple-50 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4 text-purple-800">
                {language === 'en' ? 'Deregulation Promise' : 'وعد بإلغاء التنظيم'}
              </h3>
              <p className="text-purple-900">
                {language === 'en'
                  ? 'Projects like Uniswap and Akash Network showcase how supportive regulations can unlock innovation and drive growth in the cryptocurrency ecosystem.'
                  : 'تظهر مشاريع مثل Uniswap وAkash Network كيف يمكن للتنظيمات الداعمة أن تفتح الابتكار وتدفع النمو في نظام العملات المشفرة.'}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default RegulatoryEnvironment;