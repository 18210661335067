import React from "react";
import { motion } from "framer-motion";
import { Lightbulb, Shield, BarChart3, Bot } from "lucide-react";

const features = [
  {
    icon: Lightbulb,
    title: "Adaptive Algorithms",
    description: "Our algorithmic approach is adaptive to all market conditions, evolving with the markets daily.",
    details: "With the passing of each day, our algorithms continue to evolve, unlike traditional trading strategies that often suffer from alpha decay."
  },
  {
    icon: Shield,
    title: "Risk Management",
    description: "At the core of our operations is robust risk management—no trade is executed without a predefined maximum drawdown limit."
  },
  {
    icon: BarChart3,
    title: "Portfolio Protection",
    description: "Maximum portfolio drawdown is limited to 20.47%, ensuring capital preservation while maximizing returns."
  },
  {
    icon: Bot,
    title: "Automated Trading",
    description: "We've built a trading terminal that automates order execution, streamlining the entire process and eliminating the risk of emotional errors."
  }
];

export const Features = () => {
  return (
    <section className="py-20 px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-12"
        >
          <div className="text-center">
            <h2 className="text-4xl md:text-5xl font-clash font-bold">
              <span className="text-white">Quantitative</span>{" "}
              <span className="text-spekule-cyan">Approach</span>
            </h2>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-zinc-900/80 backdrop-blur-sm rounded-2xl p-6 border border-zinc-800 hover:bg-zinc-800/80 transition-all duration-300 hover:scale-[1.02]"
              >
                <feature.icon className="w-12 h-12 text-spekule-pink mb-4" />
                <h3 className="text-xl font-clash font-bold text-white mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
                {feature.details && (
                  <p className="text-gray-500 mt-2 text-sm">{feature.details}</p>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};