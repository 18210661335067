import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../../ui/card";
import EquityCurveChart from './EquityCurveComponent';
import { ReturnsComponent } from './ReturnsComponent';
import MetricsChartsComponent from './metricsCharts';

export function AllCharts({ investmentData }) {
  const [selectedChart, setSelectedChart] = useState('equityCurve');
  const equityCurveData = investmentData?.data?.equityCurve || {};
  const returnsData = investmentData?.data?.returnsValues || {};
  const omegaData = investmentData?.data?.OmegaValues || {};
  const volatilityData = investmentData?.data?.volatilityValues || {};
  const sharpRatioData = investmentData?.data?.sharpRatioValues || {};
  const sortinoData = investmentData?.data?.sortinoValues || {};
  return (
    <Card className="w-full border border-transparent">
      <CardHeader>
        <CardTitle>Comparative Curves</CardTitle>
        <CardDescription>Performance of different investment models over 3 months</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex justify-center space-x-4 mb-4">
          <button
            onClick={() => setSelectedChart('equityCurve')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedChart === 'equityCurve' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Equity Curve
          </button>
          <button
            onClick={() => setSelectedChart('returns')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedChart === 'returns' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Returns
          </button>
        </div>
        <div className="w-full">
          {selectedChart === 'equityCurve' && <EquityCurveChart equityCurvedata={equityCurveData} />}
          {selectedChart === 'returns' && <ReturnsComponent returnsdata={returnsData} />}
        </div>
        <MetricsChartsComponent omegaData={omegaData} volatilityData={volatilityData} sharpRatioData={sharpRatioData} sortinoData={sortinoData} />
      </CardContent>
    </Card>
  );
}