import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';
import { format } from 'date-fns';

function transformData(data) {
  if (!Array.isArray(data) || data.length === 0) {
    console.error('Data is not iterable or is empty:', data);
    return [];
  }
  const [headers, ...rows] = data;
  return rows.map(row => {
    const entry = {};
    headers.forEach((header, index) => {
      entry[header] = row[index];
    });
    return entry;
  });
}

const VolatilityChart = ({ volatilityData }) => {
  const [transformedData, setTransformedData] = useState([]);
  const [disabledBars, setDisabledBars] = useState({});

  useEffect(() => {
    if (volatilityData && volatilityData.length > 0) {
      const data = transformData(volatilityData);
      setTransformedData(data);
    } else {
      setTransformedData([]);
    }
  }, [volatilityData]);

  const handleLegendClick = (dataKey) => {
    setDisabledBars(prevState => ({
      ...prevState,
      [dataKey]: !prevState[dataKey]
    }));
  };

  const spekuleTextStyle = {
    color: '#00d1b2',
    fontWeight: 'bold',
    fontSize: '1.2em',
    opacity: disabledBars['Spekule'] ? 0.5 : 1
  };

  const renderTooltip = ({ payload, label }) => {
    if (!payload || payload.length === 0) return null;

    const sortedPayload = payload.sort((a, b) => (a.name === 'Spekule' ? -1 : 1));

    return (
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '10px', border: '1px solid #ccc', textAlign: 'center', color: 'white' }}>
        <p style={{ color: 'white', fontWeight: 'bold' }}>{format(new Date(label), 'yyyy-MM-dd')}</p>
        {sortedPayload.map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{
              color: entry.color,
              fontWeight: 'bold',
              opacity: 1
            }}
          >
            {entry.name} : {entry.value}
          </p>
        ))}
      </div>
    );
  };

  return (
    <Card className="col-span-3">
      <CardHeader>
        <CardTitle>Volatility</CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={transformedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <defs>
              <linearGradient id="colorSpekule" x1="0" y1="0" x2="1" y2="0">
                <stop offset="100%" stopColor="#00d1b2" stopOpacity={1}/>
              </linearGradient>
            </defs>
            {/* <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" /> */}
            <XAxis dataKey="Date" stroke="#888" />
            <YAxis stroke="#888" />
            <Tooltip content={renderTooltip} />
            <Legend 
              onClick={(e) => handleLegendClick(e.dataKey)} 
              formatter={(value) => (
                <span style={value === 'Spekule' ? spekuleTextStyle : { opacity: disabledBars[value] ? 0.5 : 1 }}>
                  {value}
                </span>
              )}
            />
            <Bar
              dataKey="Spekule"
              fill="#00d1b2"
              stroke="#00d1b2"
              strokeWidth={2}
              fillOpacity={disabledBars['Spekule'] ? 0 : 0.3}
              strokeOpacity={disabledBars['Spekule'] ? 0 : 1}
            />
            <Bar
              dataKey="S&P 500"
              fill="#FF4C4C"
              stroke="#FF4C4C"
              strokeWidth={2}
              fillOpacity={disabledBars['S&P 500'] ? 0 : 0.3}
              strokeOpacity={disabledBars['S&P 500'] ? 0 : 1}
            />
            <Bar
              dataKey="BTCUSD"
              fill="#FFB84C"
              stroke="#FFB84C"
              strokeWidth={2}
              fillOpacity={disabledBars['BTCUSD'] ? 0 : 0.3}
              strokeOpacity={disabledBars['BTCUSD'] ? 0 : 1}
            />
            <Bar
              dataKey="ETHUSD"
              fill="#4C4CFF"
              stroke="#4C4CFF"
              strokeWidth={2}
              fillOpacity={disabledBars['ETHUSD'] ? 0 : 0.3}
              strokeOpacity={disabledBars['ETHUSD'] ? 0 : 1}
            />
            <Bar
              dataKey="Gold"
              fill="#FFFF4C"
              stroke="#FFFF4C"
              strokeWidth={2}
              fillOpacity={disabledBars['Gold'] ? 0 : 0.3}
              strokeOpacity={disabledBars['Gold'] ? 0 : 1}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default VolatilityChart;