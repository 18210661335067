import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { saveUser } from '../../services/api';
const EmailVerificationSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchUser = async () => {
            const hashParams = new URLSearchParams(location.hash.substring(1));
            const accessToken = hashParams.get('access_token');
            console.log("accessToken", accessToken);

            if (!accessToken) {
                console.error('Access token is missing');
                return;
            }

            try {
                const user = jwtDecode(accessToken);
                console.log('User:', user);
                const response = await saveUser(user);
                console.log('Response:', response);

            } catch (error) {
                console.error('Error decoding token:', error);
            }
        };

        fetchUser();
    }, [location]);

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black p-4">
            <div className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-lg text-center">
                <div className="space-y-4">
                    <div className="w-16 h-16 bg-green-500 rounded-full mx-auto flex items-center justify-center">
                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <h2 className="text-2xl font-bold text-green-500">Email Verified Successfully!</h2>
                    <p className="text-gray-300">You can now log in to your account.</p>
                    <button 
                        onClick={handleLoginRedirect}
                        className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                    >
                        Go to Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmailVerificationSuccess;