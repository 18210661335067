import React, { useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Navigation from './ClientPortalComponents/ui/navigation';
import { useUser } from '../context/UserContext';
import './TradingPage.css';
import 'react-toastify/dist/ReactToastify.css';
import { SwapComponent } from './tradingComponents/SwapComponent';
import { LimitOrdersComponent } from './tradingComponents/LimitOrderComponent';
import { ActiveOrdersComponent } from './tradingComponents/ActiveTradesComponent';
import { TradeHistoryComponent } from './tradingComponents/TradeHistoryComponent';
import { Sidebar } from './Sidebar';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { ChartComponent } from './tradingComponents/chartComponent';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { TopNav } from './top-nav';
const ResponsiveGridLayout = WidthProvider(Responsive);


const initialLayouts = {
  lg: [
    { i: "chart", x: 0, y: 0, w: 8, h: 17, minW: 6, minH: 13 },
    { i: "swap", x: 8, y: 0, w: 4, h: 17, minW: 3, minH: 13 },
    { i: "activeOrders", x: 0, y: 17, w: 6, h: 8, minW: 4, minH: 6 },
    { i: "limitOrders", x: 6, y: 17, w: 6, h: 8, minW: 4, minH: 6 },
    { i: "tradeHistory", x: 0, y: 25, w: 12, h: 8, minW: 6, minH: 6 },
  ],
  md: [
    { i: "chart", x: 0, y: 0, w: 7, h: 15, minW: 5, minH: 12 },
    { i: "swap", x: 7, y: 0, w: 3, h: 15, minW: 3, minH: 12 },
    { i: "activeOrders", x: 0, y: 15, w: 5, h: 7, minW: 4, minH: 5 },
    { i: "limitOrders", x: 5, y: 15, w: 5, h: 7, minW: 4, minH: 5 },
    { i: "tradeHistory", x: 0, y: 22, w: 10, h: 7, minW: 5, minH: 5 },
  ],
  sm: [
    { i: "chart", x: 0, y: 0, w: 6, h: 14, minW: 4, minH: 11 },
    { i: "swap", x: 0, y: 14, w: 6, h: 14, minW: 3, minH: 11 },
    { i: "activeOrders", x: 0, y: 28, w: 6, h: 6, minW: 4, minH: 4 },
    { i: "limitOrders", x: 0, y: 34, w: 6, h: 6, minW: 4, minH: 4 },
    { i: "tradeHistory", x: 0, y: 40, w: 6, h: 6, minW: 4, minH: 4 },
  ],
}

function TradingPage() {
  const [layouts, setLayouts] = useState(initialLayouts)
  const { user } = useUser();
  const role = user.role;


  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts)
  }

  if (role === 'normal') {
    return (

      <div className="flex h-screen bg-[#020817]">
        <Sidebar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <TopNav />
          <main className="flex-1 overflow-y-auto p-4">
            <ResponsiveGridLayout
              className="layout"
              layouts={layouts}
              onLayoutChange={onLayoutChange}
              breakpoints={{ lg: 1200, md: 996, sm: 768 }}
              cols={{ lg: 12, md: 10, sm: 6 }}
              rowHeight={30}
              margin={[12, 12]}
              containerPadding={[0, 0]}
              isResizable={true}
              isDraggable={true}
              draggableHandle=".drag-handle"
            >
              <div key="chart" className="bg-[#121218] rounded-lg border border-gray-800" style={{ height: '600px', overflow: 'auto' }}>
                <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                  <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                  <span className="ml-2 text-gray-200 font-medium">Chart</span>
                </div>
                <div className="p-2 h-[calc(100%-45px)]">
                  <ChartComponent />
                </div>
              </div>
              <div key="swap" className="bg-[#121218] rounded-lg border border-gray-800">
                <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                  <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                  <span className="ml-2 text-gray-200 font-medium">Trade</span>
                </div>
                <div className="p-2 h-[calc(100%-45px)] overflow-y-auto">
                  <SwapComponent />
                </div>
              </div>

              <div key="activeOrders" className="bg-[#121218] rounded-lg border border-gray-800">
                <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                  <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                  <span className="ml-2 text-gray-200 font-medium">Active Orders</span>
                </div>
                <div className="p-2 h-[calc(100%-45px)] overflow-auto">
                  <ActiveOrdersComponent />
                </div>
              </div>

              <div key="limitOrders" className="bg-[#121218] rounded-lg border border-gray-800">
                <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                  <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                  <span className="ml-2 text-gray-200 font-medium">Limit Orders</span>
                </div>
                <div className="p-2 h-[calc(100%-45px)] overflow-auto">
                  <LimitOrdersComponent />
                </div>
              </div>

              <div key="tradeHistory" className="bg-[#121218] rounded-lg border border-gray-800">
                <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                  <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                  <span className="ml-2 text-gray-200 font-medium">Trade History</span>
                </div>
                <div className="p-2 h-[calc(100%-45px)] overflow-auto">
                  <TradeHistoryComponent />
                </div>
              </div>
            </ResponsiveGridLayout>
          </main>
        </div>
      </div>
    );
  }else if (role === 'client') {
    return (
      <div className="flex flex-col h-screen">
        <Navigation />
        <main className="flex-1 overflow-y-auto p-4" style={{ marginTop: '64px' }}>
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            onLayoutChange={onLayoutChange}
            breakpoints={{ lg: 1200, md: 996, sm: 768 }}
            cols={{ lg: 12, md: 10, sm: 6 }}
            rowHeight={30}
            margin={[12, 12]}
            containerPadding={[0, 0]}
            isResizable={true}
            isDraggable={true}
            draggableHandle=".drag-handle"
          >
            <div key="chart" className="bg-[#121218] rounded-lg border border-gray-800" style={{ height: '600px', overflow: 'auto' }}>
              <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                <span className="ml-2 text-gray-200 font-medium">Chart</span>
              </div>
              <div className="p-2 h-[calc(100%-45px)]">
                <ChartComponent />
              </div>
            </div>
            <div key="swap" className="bg-[#121218] rounded-lg border border-gray-800">
              <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                <span className="ml-2 text-gray-200 font-medium">Trade</span>
              </div>
              <div className="p-2 h-[calc(100%-45px)] overflow-y-auto">
                <SwapComponent />
              </div>
            </div>

            <div key="activeOrders" className="bg-[#121218] rounded-lg border border-gray-800">
              <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                <span className="ml-2 text-gray-200 font-medium">Active Orders</span>
              </div>
              <div className="p-2 h-[calc(100%-45px)] overflow-auto">
                <ActiveOrdersComponent />
              </div>
            </div>

            <div key="limitOrders" className="bg-[#121218] rounded-lg border border-gray-800">
              <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                <span className="ml-2 text-gray-200 font-medium">Limit Orders</span>
              </div>
              <div className="p-2 h-[calc(100%-45px)] overflow-auto">
                <LimitOrdersComponent />
              </div>
            </div>

            <div key="tradeHistory" className="bg-[#121218] rounded-lg border border-gray-800">
              <div className="drag-handle p-2 border-b border-gray-800 flex items-center">
                <DragIndicatorIcon className="h-5 w-5 text-gray-400 cursor-move" />
                <span className="ml-2 text-gray-200 font-medium">Trade History</span>
              </div>
              <div className="p-2 h-[calc(100%-45px)] overflow-auto">
                <TradeHistoryComponent />
              </div>
            </div>
          </ResponsiveGridLayout>
        </main>
      </div>
    );
  }
}

export default TradingPage;


