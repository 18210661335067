import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { fetchTradeHistory } from '../../services/api';
import { Table  } from '@mui/material';
import { Card, CardContent } from "../ui/card";
import { TableHead, TableRow, TableCell, TableBody, TableHeader } from "../ui/table-header";


export function TradeHistoryComponent() {
    const { user } = useUser();
    const [tradeHistory, setTradeHistory] = useState([]);
    const tokensCache = useState(null); // Define tokensCache
  
    useEffect(() => {
      const fetchTradeHistoryInterval = setInterval(async () => {
        if (tokensCache.current) {
          setTradeHistory(tokensCache.current);
          return;
        }
        try {
          const trades = await fetchTradeHistory(user.username);
          tokensCache.current = trades; // Cache the data
          setTradeHistory(trades);
        } catch (error) {
          console.error('Error fetching trade history:', error);
        }
      }, 30000);
  
      return () => clearInterval(fetchTradeHistoryInterval);
    }, [user.username]);
  
return (
      <Card className="bg-[#0a0b0f] text-white h-96 overflow-hidden">
        <CardContent className="h-full overflow-y-auto">
          <Table>
            <TableHeader>
              <TableRow className="border-gray-800">
                <TableHead>Trade ID</TableHead>
                <TableHead>Symbol</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Side</TableHead>
                <TableHead>Entry Price</TableHead>
                <TableHead>Exit Price</TableHead>
                <TableHead>Profit/Loss</TableHead>
                <TableHead>Exchange</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tradeHistory.map((order) => (
                <TableRow key={order.tradeId} className="border-gray-800">
                  <TableCell className="font-medium">{order.tradeId}</TableCell>
                  <TableCell>{order.symbol}</TableCell>
                  <TableCell>{order.amount}</TableCell>
                  <TableCell className={order.side === "buy" ? "text-green-500" : "text-red-500"}>
                    {order.side}
                  </TableCell>
                  <TableCell>{order.entryPrice}</TableCell>
                  <TableCell>{order.exitPrice}</TableCell>
                  <TableCell>{order.PnL}</TableCell>
                  <TableCell>{order.exchange}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )


}
  