import { useEffect, useState } from 'react';
import { fetchSystemMetrics, fetchAllMetrics } from '../../services/api';
import { useUser } from '../../context/UserContext';
import { Box} from '@mui/material';
import { OneSystemAnalyticalComponent  , AllSystemAnalyticsComponent} from './AllSystemAnalyticsComponent';


export function SystemAnalyticsComponent() {
    const [systemMetrics, setSystemMetrics] = useState({});
    const [allMetrics, setAllMetrics] = useState({});
    const { user } = useUser();
    const username = user.username;
    const [selectedSystem, setSelectedSystem] = useState('');


    useEffect(() => {
        const systemAnalysisMetrics = async () => {
            try {
                const allMetricsData = await fetchAllMetrics(username);
                setAllMetrics(allMetricsData);

                const metrics = await fetchSystemMetrics(username);
                setSystemMetrics(metrics);
            } catch (error) {
                console.error('Error fetching system metrics:', error);
            }
        };
        systemAnalysisMetrics();
    }, [username]);
    const handleSystemChange = (event) => {
        setSelectedSystem(event.target.value);
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 1 }}>
                <AllSystemAnalyticsComponent allMetrics={allMetrics} />
            </Box>
            <Box sx={{ flex: 1 }}>
                <OneSystemAnalyticalComponent
                    systemMetrics={systemMetrics}
                    selectedSystem={selectedSystem}
                    handleSystemChange={handleSystemChange}
                />
            </Box>
        </Box>
    );


    // return (
    //     <Box>
    //         <Typography variant="h5">
    //             System Metrics
    //         </Typography>
    //         <Grid item xs={12}>
    //             <Card>
    //                 <CardContent>
    //                     <Typography variant="h5" component="div">
    //                         Select System
    //                     </Typography>
    //                     <Select
    //                         value={selectedSystem}
    //                         onChange={handleSystemChange}
    //                         displayEmpty
    //                         fullWidth
    //                     >
    //                         <MenuItem value="" disabled>Select a system</MenuItem>
    //                         {Object.keys(systemMetrics).map((systemName) => (
    //                             <MenuItem key={systemName} value={systemName}>
    //                                 {systemName}
    //                             </MenuItem>
    //                         ))}
    //                     </Select>
    //                 </CardContent>
    //             </Card>
    //         </Grid>

    //         {selectedSystem && (
    //             <Grid item xs={12}>
    //                 <Card>
    //                     <CardContent>
    //                         <Typography variant="h5" component="div">
    //                             System: {selectedSystem}
    //                         </Typography>
    //                         <TableContainer component={Paper}>
    //                             <Table>
    //                                 <TableHead>
    //                                     <TableRow>
    //                                         <TableCell>Metric</TableCell>
    //                                         <TableCell align="right">Value</TableCell>
    //                                     </TableRow>
    //                                 </TableHead>
    //                                 <TableBody>
    //                                     {Object.entries(systemMetrics[selectedSystem]).map(([metricName, value]) => (
    //                                         <TableRow key={metricName}>
    //                                             <TableCell>{metricName}</TableCell>
    //                                             <TableCell align="right">{value}</TableCell>
    //                                         </TableRow>
    //                                     ))}
    //                                 </TableBody>
    //                             </Table>
    //                         </TableContainer>
    //                     </CardContent>
    //                 </Card>
    //             </Grid>
    //         )}

    //     </Box>
    // );


}