import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
// client/src/components/ui/chart/ChartTooltipContent.js

import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";

const data = [
    { month: "Jan", btc: 40000, alts: 30000 },
    { month: "Feb", btc: 45000, alts: 32000 },
    { month: "Mar", btc: 50000, alts: 35000 },
    { month: "Apr", btc: 55000, alts: 37000 },
    { month: "May", btc: 60000, alts: 40000 },
    { month: "Jun", btc: 65000, alts: 42000 },
  ];

const MarketPerformance = ({ language }) => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? 'Market Performance' : 'أداء السوق'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-[300px] mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <XAxis tick={false} />
              <YAxis tick={false} />
              <Line type="monotone" dataKey="btc" stroke="#F7931A" strokeWidth={2} />
              <Line type="monotone" dataKey="alts" stroke="#627EEA" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          <div>
            <h3 className="text-xl font-semibold mb-3">
              {language === 'en' ? "Bitcoin's Dominance" : 'سيطرة البيتكوين'}
            </h3>
            <p className="text-gray-600">
              {language === 'en'
                ? 'Bitcoin proved its dominance in 2024, heavily influencing market sentiment and acting as a bellwether for the broader cryptocurrency landscape.'
                : 'أثبت البيتكوين سيطرته في عام 2024، مؤثرًا بشكل كبير على معنويات السوق وعمل كنذير للمشهد الأوسع للعملات الرقمية.'}
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-3">
              {language === 'en' ? 'Institutional Adoption' : 'التبني المؤسسي'}
            </h3>
            <p className="text-gray-600">
              {language === 'en'
                ? 'Major players like BlackRock, Fidelity, and Ark made transformative moves by introducing Spot Bitcoin ETFs and options ETFs.'
                : 'قام اللاعبون الرئيسيون مثل بلاك روك وفيديلتي وآرك بخطوات تحولية من خلال تقديم صناديق الاستثمار المتداولة في البيتكوين الفوري وخيارات ETFs.'}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MarketPerformance;