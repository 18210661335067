import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, LineChart, Brain, Terminal, TrendingUp, FileText, PlusCircle, Menu, X, BarChart, Edit as EditIcon } from 'lucide-react';
import { getConnectedExchanges, addExchange, deleteExchange } from '../../../services/api';
import { useUser } from '../../../context/UserContext';
import '../../../styles/fonts.css';
import { logoutWithSupabase, isUsernameTaken, updateUsername } from '../../../services/api';

const Navigation = () => {
  const location = useLocation();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [exchangeData, setExchangeData] = useState({ exchangeName: '', apiKey: '', apiSecret: '', passphrase: '' });
  const [connectedExchanges, setConnectedExchanges] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [usernameInput, setUsernameInput] = useState(user.username);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [error, setError] = useState('');
  const [oldUsername, setOldUsername] = useState(user.username);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logout = async () => {
    const response = await logoutWithSupabase();
    if (response.success) {
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('rememberMe');
      navigate('/');
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  const handleUsernameChange = (e) => {
    setUsernameInput(e.target.value);
  };

  const startEditingUsername = () => {
    setOldUsername(user.username);
    setIsEditingUsername(true);
  };

  const saveProfile = async () => {
    const isTaken = await isUsernameTaken(usernameInput);
    if (isTaken.success) {
      setError('Username is already taken');
    } else {
      const response = await updateUsername(oldUsername, usernameInput);
      if (response.success) {
        setError('Username updated successfully');
        setUser({ ...user, username: usernameInput });
      } else {
        setError('Failed to update username');
      }
    }
    setIsEditingUsername(false);
  };

  useEffect(() => {
    const fetchConnectedExchanges = async () => {
      if (user && user.username) {
        const exchanges = await getConnectedExchanges(user.username);
        setConnectedExchanges(exchanges);
      }
    };

    fetchConnectedExchanges();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExchangeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddExchange = async () => {
    if (!user || !user.username) {
      alert('User not logged in');
      return;
    }

    const payload = {
      username: user.username,
      exchange: exchangeData.exchangeName,
      apiKey: exchangeData.apiKey,
      apiSecret: exchangeData.apiSecret,
      passphrase: exchangeData.passphrase || ''
    };

    const result = await addExchange(payload);
    if (result.success) {
      alert('Exchange added successfully');
      setIsOpen(false);
      setConnectedExchanges([...connectedExchanges, exchangeData.exchangeName]);
    } else {
      alert(result.message || 'Failed to add exchange');
    }
  };

  const handleDeleteExchange = async (exchange) => {
    const payload = {
      username: user.username,
      exchange: exchange
    };
    const result = await deleteExchange(payload);
    if (result.success) {
      setConnectedExchanges((prevExchanges) =>
        prevExchanges.filter((e) => e !== exchange)
      );
    } else {
      alert('Failed to delete exchange');
    }
  };

  const isActive = (path) => location.pathname === path;

  const navItems = [
    { path: '/client-dashboard', label: 'Dashboard', icon: Home },
    { path: '/Screamer', label: 'Screamer', icon: LineChart },
    { path: '/ultra', label: 'Ultra', icon: Terminal },
    { path: '/Spekule-gpt', label: 'SpekuleGPT', icon: Brain },
    { path: '/trading', label: 'Trading', icon: TrendingUp },
    { path: '/coingecko', label: 'Altimeter', icon: BarChart },
    { path: '/blog', label: 'Report', icon: FileText },
  ];

  return (
    <nav className="fixed top-0 w-full bg-spekule-card/100 backdrop-blur-lg border-b border-white/10 z-40 font-montserrat">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img
              src="/logoSpekuleTransparent.png"
              alt="Spekule Logo"
              className="w-12 h-12 mr-2 cursor-pointer"
              onClick={toggleDropdown}
            />
            <span className="text-xl font-montserrat-extrabold text-white tracking-wider brand-name">Spekule</span>
          </div>

          <div className="hidden md:flex space-x-8 ml-auto">
            {navItems.map(({ path, label, icon: Icon }) => (
              <Link
                key={path}
                to={path}
                className={`nav-link flex items-center space-x-2 font-montserrat-medium ${isActive(path) ? 'active' : ''}`}
              >
                <Icon className="w-4 h-4" />
                <span>{label}</span>
              </Link>
            ))}
            <button
              onClick={() => setIsOpen(true)}
              className="flex items-center space-x-2 font-montserrat-medium text-gray-400 hover:text-white transition-colors duration-200"
            >
              <span className="text-sm">Add Exchange</span>
              <PlusCircle className="ml-2 w-4 h-4" />
            </button>
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white p-2 hover:bg-gray-800 rounded-lg transition-colors duration-200"
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-full left-0 w-full bg-spekule-card/95 backdrop-blur-lg border-b border-white/10">
            <div className="px-4 pt-2 pb-4 space-y-2">
              {navItems.map(({ path, label, icon: Icon }) => (
                <Link
                  key={path}
                  to={path}
                  className={`flex items-center space-x-3 px-4 py-3 rounded-lg ${
                    isActive(path)
                      ? 'bg-gray-800 text-white'
                      : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                  } transition-colors duration-200`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Icon className="w-5 h-5" />
                  <span className="font-montserrat-medium">{label}</span>
                </Link>
              ))}
              <button
                onClick={() => {
                  setIsMobileMenuOpen(false);
                  setIsOpen(true);
                }}
                className="w-full flex items-center space-x-3 px-4 py-3 text-gray-300 hover:bg-gray-800 hover:text-white rounded-lg transition-colors duration-200"
              >
                <PlusCircle className="w-5 h-5" />
                <span className="font-montserrat-medium">Add Exchange</span>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div ref={dropdownRef} className="absolute top-16 left-20 w-64 bg-white shadow-lg z-50">
          <div className="px-4 py-2">
            <h3 className="text-lg font-bold mb-2 text-blue-600">Profile</h3>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <p className="text-gray-900">{user.email}</p>
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">Username</label>
              {isEditingUsername ? (
                <input
                  type="text"
                  value={usernameInput}
                  onChange={handleUsernameChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                />
              ) : (
                <div className="flex items-center">
                  <p className="text-gray-900 flex-1">{user.username}</p>
                  <EditIcon
                    className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700"
                    onClick={startEditingUsername}
                  />
                </div>
              )}
            </div>
            {error && <p className={`text-sm mt-1 text-center ${error.includes('updated') ? 'text-green-500' : 'text-red-500'}`}>{error}</p>}
            <button
              className="mt-3 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
              onClick={saveProfile}
            >
              Save
            </button>
            <button
              className="mt-3 w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </div>
      )}

      {/* Exchange Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 mt-80">
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm" onClick={() => setIsOpen(false)}></div>
          <div className="relative bg-gray-900 p-8 rounded-xl shadow-2xl w-full max-w-md mx-4 border border-gray-800">
            <h3 className="text-xl font-montserrat-bold text-white mb-6 text-center">Add Exchange</h3>

            <div className="space-y-6">
              <div className="connected-exchanges">
                <h4 className="text-sm font-montserrat-medium text-gray-400 mb-3">Connected Exchanges</h4>
                <div className="flex flex-wrap gap-2">
                  {connectedExchanges.length > 0 ? (
                    connectedExchanges.map((exchange) => (
                      <span
                        key={exchange}
                        className="text-sm text-green-400 bg-gray-800/50 px-3 py-1.5 rounded-full cursor-pointer hover:bg-red-500/20 hover:text-red-400 transition-all duration-200 font-montserrat-medium"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to delete ${exchange}?`)) {
                            handleDeleteExchange(exchange);
                          }
                        }}
                      >
                        {exchange}
                      </span>
                    ))
                  ) : (
                    <span className="text-sm text-gray-500 font-montserrat-light">No exchanges connected. Connect an exchange to start trading.</span>
                  )}
                </div>
              </div>

              <form onSubmit={(e) => { e.preventDefault(); handleAddExchange(); }} className="space-y-4">
                <select
                  name="exchangeName"
                  value={exchangeData.exchangeName}
                  onChange={handleInputChange}
                  className="w-full p-3 bg-gray-800 text-white rounded-lg font-montserrat-medium border border-gray-700 focus:border-blue-500 focus:outline-none"
                >
                  <option value="">Select Exchange</option>
                  {['binance', 'kucoin', 'bitget', 'bybit']
                    .filter(exchange => !connectedExchanges.includes(exchange))
                    .map(exchange => (
                      <option key={exchange} value={exchange}>
                        {exchange.charAt(0).toUpperCase() + exchange.slice(1)}
                      </option>
                    ))}
                </select>

                <input
                  type="text"
                  name="apiKey"
                  placeholder="API Key"
                  value={exchangeData.apiKey}
                  onChange={handleInputChange}
                  className="w-full p-3 bg-gray-800 text-white rounded-lg font-montserrat-regular border border-gray-700 focus:border-blue-500 focus:outline-none"
                />

                <input
                  type="text"
                  name="apiSecret"
                  placeholder="API Secret"
                  value={exchangeData.apiSecret}
                  onChange={handleInputChange}
                  className="w-full p-3 bg-gray-800 text-white rounded-lg font-montserrat-regular border border-gray-700 focus:border-blue-500 focus:outline-none"
                />

                {(exchangeData.exchangeName === 'bitget' || exchangeData.exchangeName === 'kucoin') && (
                  <input
                    type="text"
                    name="passphrase"
                    placeholder="Passphrase"
                    value={exchangeData.passphrase}
                    onChange={handleInputChange}
                    className="w-full p-3 bg-gray-800 text-white rounded-lg font-montserrat-regular border border-gray-700 focus:border-blue-500 focus:outline-none"
                  />
                )}

                <div className="flex gap-3 mt-6">
                  <button
                    type="submit"
                    className="flex-1 p-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg font-montserrat-medium transition-colors duration-200"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className="flex-1 p-3 bg-gray-700 hover:bg-gray-600 text-white rounded-lg font-montserrat-medium transition-colors duration-200"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;