import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import TradingPage from './components/TradingPage';
import PortfolioPage from './components/PortfolioPage';
import SpekuleGpt from './components/SpekuleGpt';
import CoingeckoPage from './components/CoingeckoPage';
import UltraPage from './components/UltraPage';
import ScreamerPage from './components/ScreamerPage';
import './styles/main.css';
import './styles/main.css';
import RegisterPage from './components/RegisterPage';
import { LandingPage } from './components/LandingPageComponents/LandingPage';
import ClientDashboard from './components/ClientDashboard'; // Create this component
import ProtectedRoute from './components/ProtectedRoute';
import UnauthorizedPage from './components/UnauthorizedPage'
import BlogPage from './components/BlogPage';
import EmailVerificationSuccess from './components/auth/EmailVerificationSuccess';
import GoogleCallback from './components/auth/callback';
import { ForgotPassword } from './components/auth/ForgotPassword';
import './styles/global.css';
import ClientRegisterForm from './components/auth/ClientRegisterForm';

function App() {




  return (
    <UserProvider>
      <Router>
        <main className="app-main">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/verify-email-success" element={<EmailVerificationSuccess />} />
            <Route path="/callback" element={<GoogleCallback />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/client-register" element={<ClientRegisterForm />} />


            <Route path="/trading/dashboard"
              element={<ProtectedRoute allowedRoles={['normal']} redirectPath="/client-dashboard">
                <Dashboard />
              </ProtectedRoute>} />
            <Route path="/trading"
              element={<ProtectedRoute allowedRoles={['client', 'normal']}>
                <TradingPage />
              </ProtectedRoute>} />
            <Route path="/portfolio"
              element={<ProtectedRoute allowedRoles={['normal']} redirectPath="/trading/dashboard">
                <PortfolioPage />
              </ProtectedRoute>} />
            <Route path="/spekule-gpt"
              element={<ProtectedRoute allowedRoles={['client', 'normal']}>
                <SpekuleGpt />
              </ProtectedRoute>} />
            <Route path="/coingecko"
              element={<ProtectedRoute allowedRoles={['client', 'normal']}>
                <CoingeckoPage />
              </ProtectedRoute>} />
            <Route path="/ultra"
              element={<ProtectedRoute allowedRoles={['client', 'normal']}>
                <UltraPage />
              </ProtectedRoute>} />
            <Route path="/screamer"
              element={<ProtectedRoute allowedRoles={['client', 'normal']}>
                <ScreamerPage />
              </ProtectedRoute>} />
            <Route path="/" element={<Home />} />
            <Route
              path="/client-dashboard"
              element={
                <ProtectedRoute allowedRoles={['client']}>
                  <ClientDashboard />
                </ProtectedRoute>
              }
            />
            <Route 
            path="/blog" 
            element={
              <ProtectedRoute allowedRoles={['client']}>
                <BlogPage />  
              </ProtectedRoute>
            } 
            />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
          </Routes>

        </main>
      </Router>
    </UserProvider>
  );
}



function Home() {
  return <LandingPage />;
}

export default App;






