import React from 'react';
import {GoogleSheetsComponent } from './tradingComponents/GoogleSheetsComponent';
import { Sidebar } from './Sidebar';
import { Typography } from '@mui/material';
import { TopNav } from './top-nav';
import { useUser } from '../context/UserContext';
import Navigation from './ClientPortalComponents/ui/navigation';

function ScreamerPage() {
    const { user } = useUser();
    const UserRole = user.role;

    if (UserRole === 'normal') {
        return (  
            <div className="flex h-screen bg-background">
                <Sidebar />
                <div className="flex flex-col flex-1 overflow-hidden">
                    <TopNav />
                    <main className="flex-1 overflow-y-auto p-4">
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                            <GoogleSheetsComponent />
                        </div>
                    </main>
                </div>
            </div>
        );
    } else if (UserRole === 'client') {
        return (
            <div className="flex flex-col flex-1 overflow-hidden">
                <Navigation />
                <main className="flex-1 overflow-y-auto p-4 flex flex-col items-center mt-20">
                    <Typography variant="h4" className="font-bold mb-6 text-center">
                        The Best Tokens on the Market Today
                    </Typography>
                    <div className="p-4 flex items-center">
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                            <GoogleSheetsComponent />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}


export default ScreamerPage;