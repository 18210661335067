import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const SpekulePerformance = ({ language }) => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? "Spekule's 2024 Performance" : 'أداء سبيكول لعام 2024'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-8">
          <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-blue-300">
              {language === 'en' ? 'Performance Highlights' : 'أبرز الأداء'}
            </h3>
            <p className="text-gray-800">
              {language === 'en'
                ? 'Our trend-following algorithms excelled in capturing gains and timing market exits, validating our analysis of global liquidity trends and monetary policy shifts.'
                : 'تفوقت خوارزميات تتبع الاتجاهات لدينا في تحقيق المكاسب وتوقيت الخروج من السوق، مما يؤكد تحليلنا لاتجاهات السيولة العالمية وتحولات السياسة النقدية.'}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-3 text-blue-800">
                {language === 'en' ? 'Liquidity Expansion' : 'توسع السيولة'}
              </h3>
              <p className="text-blue-900">
                {language === 'en'
                  ? 'Mid-cycle analysis indicates continued liquidity growth, supported by global central banks and private sector contributions.'
                  : 'يشير التحليل في منتصف الدورة إلى استمرار نمو السيولة، بدعم من البنوك المركزية العالمية ومساهمات القطاع الخاص.'}
              </p>
            </div>
            <div className="bg-purple-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-3 text-purple-800">
                {language === 'en' ? 'Market Context' : 'سياق السوق'}
              </h3>
              <p className="text-purple-900">
                {language === 'en'
                  ? 'Federal Reserve policies and broader macroeconomic factors continue to support a bullish outlook for crypto markets.'
                  : 'تواصل سياسات الاحتياطي الفيدرالي والعوامل الاقتصادية الكلية الأوسع دعم النظرة الإيجابية لأسواق العملات المشفرة.'}
              </p>
            </div>
            <div className="bg-indigo-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-3 text-indigo-800">
                {language === 'en' ? 'Bitcoin Supercycle' : 'الدورة الفائقة للبيتكوين'}
              </h3>
              <p className="text-indigo-900">
                {language === 'en'
                  ? "Emerging theory suggests Bitcoin may enter a prolonged growth phase, similar to gold's 15-year ascent."
                  : 'تشير النظرية الناشئة إلى أن البيتكوين قد يدخل في مرحلة نمو مطولة، مشابهة لصعود الذهب لمدة 15 عامًا.'}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SpekulePerformance;