import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotificationPanel } from './tradingComponents/NotificationPanel';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { Edit as EditIcon } from '@mui/icons-material';
import { logoutWithSupabase, updateUsername, isUsernameTaken } from '../services/api';
import '../styles/fonts.css';
import { useUser } from '../context/UserContext';

export function TopNav() {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { user, setUser } = useUser();
  const [error, setError] = useState(null);
  const [usernameInput, setUsernameInput] = useState(user.username);
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [oldUsername, setOldUsername] = useState(user.username);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logout = async () => {
    const response = await logoutWithSupabase();
    if (response.success) {
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('rememberMe');
      navigate('/');
    }
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  const handleUsernameChange = (e) => {
    setUsernameInput(e.target.value);
  };

  const startEditingUsername = () => {
    setOldUsername(user.username);
    setIsEditingUsername(true);
  };

  const saveProfile = async () => {
    const isTaken = await isUsernameTaken(usernameInput);
    if (isTaken.success) {
      setError('Username is already taken');
    } else {
      const response = await updateUsername(oldUsername, usernameInput);
      if (response.success) {
        setError('Username updated successfully');
        setUser({ ...user, username: usernameInput });
      } else {
        setError('Failed to update username');
      }
    }
    setIsEditingUsername(false);
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/trading':
        return 'TRADING';
      case '/portfolio':
        return 'PORTFOLIO';
      case '/spekule-GPT':
        return 'SPEKULEGPT';
      case '/trading/dashboard':
        return 'DASHBOARD';
      case '/coingecko':
        return 'TOKENS FIB LEVELS';
      case '/ultra':
        return 'ULTRATRADER';
      case '/screamer':
        return 'SCREAMER WATCHLIST';
      default:
        return 'SPEKULE TERMINAL';
    }
  };

  return (
    <header className="flex h-16 items-center justify-between border-b px-4 font-montserrat">
      <div className="text-lg font-montserrat-extrabold animate-pulse">{getPageTitle()}</div>
      <div className="flex items-center gap-6 ml-auto">
        <button onClick={togglePanel} className="font-montserrat-medium">
          <NotificationsIcon />
        </button>
        {isPanelOpen && (
          <NotificationPanel 
            notifications={notifications} 
            className="font-montserrat-regular"
          />
        )}
        <div className="relative" ref={dropdownRef}>
          <img 
            src="/logoSpekuleTransparent.png" 
            alt="Spekule Logo" 
            className="w-8 h-8 hover:w-12 hover:h-12 transition-transform duration-300 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-4">
              <h3 className="text-lg font-bold mb-2 text-blue-600">Profile</h3>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <p className="text-gray-900">{user.email}</p>
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Username</label>
                {isEditingUsername ? (
                  <input
                    type="text"
                    value={usernameInput}
                    onChange={handleUsernameChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  />
                ) : (
                  <div className="flex items-center">
                    <p className="text-gray-900 flex-1">{user.username}</p>
                    <EditIcon 
                      className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700"
                      onClick={startEditingUsername}
                    />
                  </div>
                )}
              </div>
              {error && <p className={`text-sm mt-1 text-center ${error.includes('updated') ? 'text-green-500' : 'text-red-500'}`}>{error}</p>}
              <button 
                className="mt-3 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
                onClick={saveProfile}
              >
                Save
              </button>
              <button 
                className="mt-3 w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700"
                onClick={logout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>    
    </header>
  );
}
