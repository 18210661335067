import Plotly from 'plotly.js-dist';
import { useEffect, useState } from "react";
import {  fetchOrderBookforHistogram } from "../../services/api";

export function OrderBookHistogramComponent(props) {
  const { symbol: symbolProps } = props;
  const [symbol, setSymbol] = useState(symbolProps || 'BTCUSDT');
  const [loading, setLoading] = useState(false);

  // Effect to handle symbol prop changes from parent
  useEffect(() => {
    if (symbolProps) {
      setSymbol(symbolProps);
      handleFetchData(symbolProps);
    }
  }, [symbolProps]); // This will update when the parent changes the symbol

  // Initial data fetch
  useEffect(() => {
    handleFetchData(symbol);
  }, []); // Only run on mount

  const handleFetchData = async (currentSymbol) => {
    if (!currentSymbol) return;
    
    try {
      setLoading(true);
      const data = await fetchOrderBookforHistogram(currentSymbol);
      
      if (!data || data.length === 0) {
        console.error('No data received from API');
        return;
      }

      plotOrderBookData(data, currentSymbol);

    } catch (error) {
      console.error('Error fetching order book data:', error);
    } finally {
      setLoading(false);
    }
  };

  const plotOrderBookData = (data, currentSymbol) => {
    if (!data || !Array.isArray(data) || data.length === 0) return;

    const traces = data.flatMap(({ exchange, data }) => {
      if (!data || !data.volumes || !data.prices) return [];

      return [{
        x: data.volumes.filter(v => !isNaN(v)),
        y: data.prices.filter(p => !isNaN(p)),
        type: 'bar',
        orientation: 'h',
        name: exchange,
        marker: {
          color: getExchangeColor(exchange)
        }
      }];
    }).filter(Boolean);

    const layout = {
      title: {
        text: `Order Book Data: ${currentSymbol}`,
        font: { color: 'white' }
      },
      xaxis: {
        title: 'Volume',
        color: 'white',
        automargin: true,
        autorange: true,
        gridcolor: '#333'
      },
      yaxis: {
        title: 'Price',
        color: 'white',
        automargin: true,
        autorange: true,
        fixedrange: false,
        gridcolor: '#333'
      },
      paper_bgcolor: '#000',
      plot_bgcolor: '#000',
      showlegend: true,
      legend: {
        font: { color: 'white' },
        bgcolor: 'rgba(0,0,0,0.5)'
      },
      bargap: 0.05,
      bargroupgap: 0.2,
      margin: { t: 50, r: 50, b: 50, l: 50 }
    };

    const config = {
      responsive: true,
      scrollZoom: true,
      displayModeBar: true,
      modeBarButtonsToAdd: ['zoom2d', 'pan2d', 'resetScale2d']
    };
  
    try {
      const chartDiv = document.getElementById('orderBookChart');
      if (chartDiv) {
        Plotly.newPlot('orderBookChart', traces, layout, config);
      }
    } catch (error) {
      console.error("Error plotting chart:", error);
    }
  };

  const getExchangeColor = (exchange) => {
    const colors = {
      'binance': '#F0B90B',      // Binance yellow
      'okx': '#1DDB9C',          // OKX green
      'kucoin': '#26A17B',       // KuCoin green
      'bitget': '#FF5733',       // Bitget orange
      'coinbase': '#0052FF',     // Coinbase blue
      'bitgetFutures': '#FF8C00', // Bitget Futures dark orange
      'okxFutures': '#00FF7F',    // OKX Futures light green
      'binanceFutures': '#FFD700', // Binance Futures gold
      'kucoinFutures': '#1EA37D'  // KuCoin Futures green
    };
    // Trim any whitespace from the exchange name before lookup
    const trimmedExchange = exchange.trim();
    return colors[trimmedExchange] || '#ffffff';
  };

  return (
    <div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
        </div>
      )}
      <div 
        id="orderBookChart" 
        style={{ 
          width: '100%', 
          height: '500px',
          position: 'relative'
        }}
      />
    </div>
  );
}