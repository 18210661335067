// lib/fetchInvestmentData.js
import axios from 'axios';
const BackendUrl = process.env.REACT_APP_API_BASE_URL;

export const insertintouser = async (username, email) => {
  try {
    const response = await axios.post(`${BackendUrl}/api/insertintouser`, {
      username: username,
      email: email,
      role: 'client'
    });
    return response.data;
  } catch (error) {
    console.error('Error inserting into user:', error);
    return [];
  }
};

export async function fetchInvestmentData(username) {
  try {
    console.log("inside the client function")
    const response = await axios.post(`${BackendUrl}/api/investment-data`, { 
      username: username 
    });
    console.log("response isnide client api", response)
    // Transform the data

    return response.data;
  } catch (error) {
    console.error('Error fetching investment data:', error);
    return [];
  }
}

export const googleLogin = async () => {
  console.log("inside the google login function")
  try {
    const response = await axios.get(`${BackendUrl}/api/loginWithGoogle`);
    console.log("response inside google login function", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching investment data:', error);
    return [];
  }
}

export const fetchPieChartData = async () => {
  try {
    const response = await axios.get(`${BackendUrl}/api/piechart`);
    return response.data;
  } catch (error) {
    console.error('Error fetching pie chart data:', error);
    return [];
  }
};

export const fetchUserAssets = async (email) => {
  try {
    const response = await axios.get(`${BackendUrl}/api/user-assets?email=${email}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user assets:', error);
    throw error;
  }
};



export const fetchBlogContent = async () => {
  try {
    const response = await axios.get(`${BackendUrl}/api/report`);
    return response.data;
  } catch (error) {
    console.error('Error fetching blog content:', error);
    return '';
  }
};

export const saveBlogContent = async (content) => {
  try {
    const response = await axios.post(`${BackendUrl}/api/save-report`, { content });
    return response.data;
  } catch (error) {
    console.error('Error saving blog content:', error);
    throw error;
  }
};