import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Card } from "../ui/card";

const data = [
  { month: "Jan", value: 10000 },
  { month: "Feb", value: 10000 },
  { month: "Mar", value: 15000 }, 
  { month: "Apr", value: 25000 },
  { month: "May", value: 40000 },
  { month: "Jun", value: 45000 },
  { month: "Jul", value: 45000 },
  { month: "Aug", value: 60000 },
  { month: "Sep", value: 60000 },
  { month: "Oct", value: 80000 },
  { month: "Nov", value: 80000 },
  { month: "Dec", value: 100000 }
];

export const ChartSection = () => {
  return (
    <Card className="p-6 glass-card">
      <h3 className="text-xl font-semibold mb-4">ROI Growth Over Time</h3>
      <div className="h-[300px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="hsl(var(--accent))" stopOpacity={0.8} />
                <stop offset="95%" stopColor="hsl(var(--accent))" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="month"
              stroke="hsl(var(--muted-foreground))"
              fontSize={12}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              stroke="hsl(var(--muted-foreground))"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `$${value.toLocaleString()}`}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="bg-background p-2 border border-border rounded-lg shadow-lg">
                      <p className="text-sm font-medium">
                        ${payload[0].value.toLocaleString()}
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="hsl(var(--accent))"
              fillOpacity={1}
              fill="url(#colorValue)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};