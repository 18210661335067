import React from "react";
import { motion } from "framer-motion";

export const Introduction = () => {
  return (
    <section className="py-20 px-6 relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="grid md:grid-cols-2 gap-12 items-center"
        >
          <div className="space-y-6">
            <h2 className="text-4xl md:text-5xl font-clash font-bold">
            <span className="text-spekule-blue">Introduction</span>            
            </h2>
            <div className="bg-zinc-900/80 backdrop-blur-sm rounded-2xl p-8 border border-zinc-800 hover:bg-zinc-800/80 transition-all duration-300 hover:scale-[1.02]">
              <h3 className="text-xl text-spekule-cyan mb-4 font-clash">Spekule</h3>
              <p className="text-gray-300">
                Spekule is a quantitative and algorithmic asset management firm specializing in cryptocurrency markets. Over the past 1.5 years, we have experienced rapid growth, expanding our Assets Under Management (AUM) to the mid six-figure range.
              </p>
            </div>
            <div className="bg-zinc-900/80 backdrop-blur-sm rounded-2xl p-8 border border-zinc-800 hover:bg-zinc-800/80 transition-all duration-300 hover:scale-[1.02]">
              <h3 className="text-xl text-spekule-pink mb-4 font-clash">Our Impressive Growth</h3>
              <p className="text-gray-300">
                Our impressive growth has been fueled by exceptional performance, delivering a{" "}
                <span className="text-spekule-cyan font-bold">354%</span> return since Q4 of last year.
              </p>
            </div>
          </div>
          <div className="relative">
            <img
              src="blackSpekule.jpeg"
              alt="Trading Chart"
              className="rounded-2xl bg-zinc-900/80 backdrop-blur-sm p-2 border border-zinc-800"
            />
          </div>
        </motion.div>
      </div>
    </section>
  );
};