import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const TrumpAdministration = ({ language }) => {
  const members = [
    {
      name: language === 'en' ? "Donald Trump" : "دونالد ترامب", 
      role: language === 'en' ? "President" : "الرئيس",
      netWorth: language === 'en' ? "$5.4 billion" : "5.4 مليار دولار",
      image: "donald.png",
    },
    {
      name: language === 'en' ? "Elon Musk" : "إيلون ماسك",
      role: language === 'en' ? "Co-chair of DOGE" : "الرئيس المشارك لـ DOGE",
      netWorth: language === 'en' ? "Over $300 billion" : "أكثر من 300 مليار دولار",
      image: "elon.jpeg",
    },
    {
      name: language === 'en' ? "Vivek Ramaswamy" : "فيفيك راماسوامي",
      role: language === 'en' ? "Co-chair of DOGE" : "الرئيس المشارك لـ DOGE",
      netWorth: language === 'en' ? "$1 billion" : "1 مليار دولار",
      image: "Vivek.jpg",
    },
    {
      name: language === 'en' ? "Scott Bessent" : "سكوت بيسنت",
      role: language === 'en' ? "Treasury secretary" : "وزير الخزانة",
      netWorth: language === 'en' ? "Reported billionaire" : "مليونير مذكور",
      image: "scott.webp",
    },
    {
      name: language === 'en' ? "Howard Lutnick" : "هوارد لوتنيك",
      role: language === 'en' ? "Commerce secretary" : "وزير التجارة",
      netWorth: language === 'en' ? "$2 billion" : "2 مليار دولار ",
      image: "howard.jpg",
    },
    {
      name: language === 'en' ? "Linda McMahon" : "ليندا مكماهون",
      role: language === 'en' ? "Education secretary" : "وزيرة التعليم",
      netWorth: language === 'en' ? "$2.6 billion" : "2.6 مليار دولار ",
      image: "linda.jpg",
    },
    {
      name: language === 'en' ? "Chris Wright" : "كريس رايت",
      role: language === 'en' ? "Energy secretary" : "وزير الطاقة",
      netWorth: language === 'en' ? "$171 million" : "171 مليون دولار ",
      image: "chris.webp",
    },
    {
      name: language === 'en' ? "Doug Burgum" : "دوغ بورغوم",
      role: language === 'en' ? "Interior secretary" : "وزير الداخلية",
      netWorth: language === 'en' ? "$1.1 billion" : "1.1 مليار دولار ",
      image: "doug.webp",
    },
    {
      name: language === 'en' ? "Steven Witkoff" : "ستيفن ويتكوف",
      role: language === 'en' ? "Middle East envoy" : "مبعوث الشرق الأوسط",
      netWorth: language === 'en' ? "Over $500 million" : "أكثر من 500 مليون دولار ",
      image: "steven.avif",
    },
    {
      name: language === 'en' ? "Massad Boulos" : "مسعد بولس",
      role: language === 'en' ? "Middle East adviser" : "مستشار الشرق الأوسط",
      netWorth: language === 'en' ? "Reported billionaire" : "مليونير مذكور ",
      image: "massad.jpeg",
    },
    {
      name: language === 'en' ? "Stephen Feinberg" : "ستيفن فاينبرغ",
      role: language === 'en' ? "Deputy defense secretary" : "نائب وزير الدفاع",
      netWorth: language === 'en' ? "$5 billion " : "5 مليار دولار ",
      image: "stephen.webp",
    },
    {
      name: language === 'en' ? "Warren Stephens" : "وارن ستيفنز",
      role: language === 'en' ? "Ambassador to U.K." : "سفير إلى المملكة المتحدة",
      netWorth: language === 'en' ? "$3.3 billion" : "3.3 مليار دولار ",
      image: "warren.jpeg",
    },
    {
      name: language === 'en' ? "Charles Kushner" : "تشارلز كوشنر",
      role: language === 'en' ? "Ambassador to France" : "سفير إلى فرنسا",
      netWorth: language === 'en' ? "$2.9 billion" : "2.9 مليار دولار ",
      image: "charles.jpg",
    },
    {
      name: language === 'en' ? "Mehmet Oz" : "محمد أوز",
      role: language === 'en' ? "CMS administrator" : "مدير CMS",
      netWorth: language === 'en' ? "At least $100 million" : "أكثر من 100 مليون دولار  ",
      image: "mehmet.jpeg",
    },
    {
      name: language === 'en' ? "Jared Isaacman" : "جاريد إيزاكمان",
      role: language === 'en' ? "NASA administrator" : "مدير ناسا",
      netWorth: language === 'en' ? "$1.9 billion" : "1.9 مليار دولار ",
      image: "jared.png",
    },
    {
      name: language === 'en' ? "Frank Bisignano" : "فرانك بيسيجنانو",
      role: language === 'en' ? "Social Security commissioner" : "مفوض الضمان الاجتماعي",
      netWorth: language === 'en' ? "Over $900 million" : "أكثر من 900 مليون دولار ",
      image: "frank.jpeg",
    },
    {
      name: language === 'en' ? "Kelly Loeffler" : "كيلي لوفلر",
      role: language === 'en' ? "Small business administrator" : "مدير الأعمال الصغيرة",
      netWorth: language === 'en' ? "$1.1 billion" : "1.1 مليار دولار ",
      image: "kelly.webp",
      },
    {
      name: language === 'en' ? "David Sacks" : "ديفيد ساكس",
      role: language === 'en' ? "AI & crypto czar" : "قيصر الذكاء الاصطناعي والعملات الرقمية",
      netWorth: language === 'en' ? "Reported billionaire" : "مليونير مذكور ",
      image: "david.webp",
    },
  ];

  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? "Trump Administration's Wealthiest Members" : "أغنى أعضاء إدارة ترامب"}
        </CardTitle>
        <p className="text-muted-foreground">
          {language === 'en' ? "As of Dec. 5, 2024" : "اعتبارًا من 5 ديسمبر 2024"}
        </p>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {members.map((member, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 bg-gradient-to-b from-background to-muted rounded-lg hover:shadow-lg transition-transform transform hover:scale-105"
            >
              <div className="w-24 h-24 rounded-full border-4 border-green-400 overflow-hidden mb-4">
                <img
                  src={`/AdministrationWealthiestMembers/${member.image}`}
                  alt={member.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="font-semibold text-lg mb-1">{member.name}</h3>
              <p className="text-muted-foreground text-sm mb-2">{member.role}</p>
              <p className="text-primary font-medium">{member.netWorth}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TrumpAdministration;