// client/src/components/ClientDashboard.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent} from "./ui/card";
import { fetchInvestmentData } from "../services/clientApi";
import { useUser } from '../context/UserContext';
import { AllCharts } from './ClientPortalComponents/charts/Allchart';
import UserAssetsPieChart from './ClientPortalComponents/portfolio/ClientAssets';
import Navigation from './ClientPortalComponents/ui/navigation';
import '../styles/fonts.css';

export function ClientDashboard() {
  const { user } = useUser();
  const [investmentData, setInvestmentData] = useState({});
  console.log("user", user);
  console.log("user email inside client dashboard", user.email);
  useEffect(() => {
    async function loadData() {
      const data = await fetchInvestmentData(user.username);
      console.log("data inside client dashboard", data);
      console.log('Fetched Investment Data:', data);
      setInvestmentData(data || {});
    }
    loadData();
  }, [user.username]);

  return (
    <div className="font-montserrat">
      <Navigation />
      <Card className="w-full mt-16">
        <CardContent className="font-montserrat-medium">
          <UserAssetsPieChart email={user.email} />
          <AllCharts investmentData={investmentData} />
        </CardContent>
      </Card>
    </div>
  );
}

export default ClientDashboard;