import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../../services/api'; // Assume you have a register function in your API service
// import './LoginRegisterForm.css';
import './LoginForm.css';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/card';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { Mail } from 'lucide-react';
import { Label } from '../ui/label';
import { Button } from '../ui/Button';
import { User, Lock, Eye, EyeOff } from 'lucide-react';
import {Link} from 'react-router-dom';
import { googleLogin } from '../../services/clientApi';
import { isUsernameTaken, isEmailTaken } from '../../services/api';
async function handleGoogleLoginResponse() {
  try {
    const response = await googleLogin();
    if (response.success) {
      openUrl(response.redirectUrl);
    } else {
      alert('Failed to redirect to Google for authentication.');
    }
  } catch (error) {
    console.error('Google login error:', error);
    alert('An error occurred during Google login.');
  }
}

function openUrl(url) {
  if (url) {
    window.open(url, '_blank');
  } else {
    alert('Failed to redirect to Google for authentication.');
  }
}

export function RegisterFormComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');


    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        const isUsername = await isUsernameTaken(username);
        console.log("isUsername", isUsername);
        const isEmail = await isEmailTaken(email);
        console.log("isEmail", isEmail);

        if (isUsername.success) {
            setError('Username is already registered');
            return;
        }
        if (isEmail.success) {
            setError('Email is already registered');
            return;
        }

        try {
            try {
                // Register in backend
                const response = await register(username, password, email );
                const data = await response.json();
                
                if (data.success) {
                    alert('Registration successful! Please check your email for verification link.');
                    navigate('/login');
                } else {
                    throw new Error(data.message || 'Registration failed');
                }
            } catch (backendError) {
                throw backendError;
            }
        } catch (error) {
            console.error('Registration error:', error);
            if (error.code === 'auth/email-already-in-use') {
                alert('This email is already registered. Please use a different email or try logging in.');
            } else {
                setError(error.message || 'Failed to register. Please try again.');
            }
        }
    };

    const togglePasswordVisibility = () => setShowPassword(!showPassword)
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword)
  const GoogleIcon = ({ className = "" }) => (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <path
        fill="#FFC107"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      />
      <path
        fill="#FF3D00"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      />
      <path
        fill="#4CAF50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      />
      <path
        fill="#1976D2"
        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      />
    </svg>
  );

  return (
    <Card className="w-full max-w-md bg-card/50 backdrop-blur-md border-border">
      <CardHeader className="space-y-1 flex flex-col items-center">
        <div className="relative w-32 h-32 mb-4">
          <img
            src="/logoSpekuleTransparent.png"
            alt="Crypto Trading Terminal Logo"
            className="object-contain w-full h-full"
          />
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-foreground">Create an account</h1>
        <p className="text-muted-foreground">Enter your details to register</p>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          <div>
            <Label htmlFor="username" className="text-gray-200">Username</Label>
            <Input
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <User className="text-white" size={18} />
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="email" className="text-gray-200">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Mail className="text-white" size={18} />
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="password" className="text-gray-200">Password</Label>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Lock className="text-white" size={18} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeOff className="text-gray-400" size={18} />
                    ) : (
                      <Eye className="text-gray-400" size={18} />
                    )}
                  </Button>
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="confirmPassword" className="text-gray-200">Confirm Password</Label>
            <Input
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Lock className="text-white" size={18} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <EyeOff className="text-muted-foreground" size={18} />
                    ) : (
                      <Eye className="text-muted-foreground" size={18} />
                    )}
                  </Button>
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button type="submit" className="w-full bg-primary text-primary-foreground hover:bg-primary/90">
            Create Account
          </Button>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <Button
            type="button"
            variant="outline"
            className="
            w-full relative bg-white hover:bg-gray-50 text-gray-900 
            transition-all duration-300 ease-in-out
            flex items-center justify-center gap-3 py-6
            border border-gray-200 hover:border-gray-300
            shadow-sm hover:shadow-md
          "            
          onClick={handleGoogleLoginResponse}
          >
            <GoogleIcon className="w-5 h-5" />
            <span className="text-[15px] font-medium">Continue with Google</span>
          </Button>
          <div className="text-sm text-muted-foreground text-center">
            Already have an account?{' '}
            <Link to="/login" className="text-primary hover:underline">
              Sign in
            </Link>
          </div>
        </CardFooter>
      </form>
    </Card>
  );
}

// export default RegisterForm;
