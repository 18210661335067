import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const Conclusion = ({ language }) => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {language === 'en' ? 'Conclusion' : 'الخاتمة'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="bg-gradient-to-r from-blue-600 to-purple-600 p-6 rounded-lg text-white">
            <p className="mb-4">
              {language === 'en'
                ? 'The cryptocurrency market in 2024 has established a robust foundation through innovation, institutional involvement, and evolving regulatory frameworks, setting the stage for continued growth in 2025.'
                : 'لقد أسس سوق العملات المشفرة في عام 2024 أساسًا قويًا من خلال الابتكار والمشاركة المؤسسية والأطر التنظيمية المتطورة، مما يمهد الطريق للنمو المستمر في عام 2025.'}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-3 text-blue-800">
                {language === 'en' ? 'Strategic Focus' : 'التركيز الاستراتيجي'}
              </h3>
              <p className="text-blue-900">
                {language === 'en'
                  ? 'Spekule maintains its commitment to data-driven strategies and quantitative trading systems, ensuring adaptability in dynamic market conditions.'
                  : 'تحافظ سبيكول على التزامها بالاستراتيجيات المستندة إلى البيانات وأنظمة التداول الكمية، مما يضمن التكيف في ظروف السوق الديناميكية.'}
              </p>
            </div>
            <div className="bg-purple-50 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-3 text-purple-800">
                {language === 'en' ? 'Future Outlook' : 'التوقعات المستقبلية'}
              </h3>
              <p className="text-purple-900">
                {language === 'en'
                  ? 'The foundation laid in 2024 provides fertile ground for continued innovation and market expansion in the cryptocurrency ecosystem.'
                  : 'يوفر الأساس الذي تم وضعه في عام 2024 أرضًا خصبة للابتكار المستمر وتوسع السوق في نظام العملات المشفرة.'}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Conclusion;