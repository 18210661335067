// client/src/services/api.js
import axios from 'axios';
const BackendUrl = process.env.REACT_APP_API_BASE_URL;
export async function loginWithSupabase(userEmail, password) {
    try {
        console.log("Logging in with Supabase:", { userEmail, password });
        const response = await fetch(`${BackendUrl}/api/loginWithSupabase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail, password }),
        });
        console.log("Supabase response:", response);
        //hello
        const { success, message, data, username, role, access_token, email } = await response.json();
        const result = { success, message, data, username, role, access_token, email }; 
        console.log("result inside loginWithSupabase api", result);
        return result;
        
    } catch (error) {
        console.error('Error logging in with Supabase:', error);
        throw error;
    }
}

export async function validateToken(token) {
    try {
      const response = await fetch(`${BackendUrl}/api/validate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Token validation failed');
      }
  
      const userData = await response.json();
      return userData; // Return user data if valid
    } catch (error) {
      console.error('Token validation error:', error);
      return null; // Return null if invalid
    }
  }

export async function login(username, password) {
    try {
        const response = await fetch(`${BackendUrl}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const userData = await response.json();

        return userData;
    } catch (error) {
        console.error('Error during login:', error);
        return { success: false, message: error.message };
    }
}

export async function sendingResetPassword(email) {
    try {
        const response = await fetch(`${BackendUrl}/api/sending-reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
        return response.json();
    } catch (error) {
        console.error('Error sending reset password email:', error);
        return { success: false, message: error.message };
    }
}
export async function isUsernameTaken(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/isUsernameTaken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username }),
        });
        const data = await response.json();
        console.log("data inside isUsernameTaken", data);
        return data;
    } catch (error) {
        console.error('Error checking username :', error);
        return { success: false, message: error.message };
    }
}

export async function updateUsername(oldUsername, newUsername) {
    try {
        const response = await fetch(`${BackendUrl}/api/update-username`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ oldUsername, newUsername }),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating username:', error);
        return { success: false, message: error.message };
    }
}

export async function isEmailTaken(email) {
    try {
        const response = await fetch(`${BackendUrl}/api/isEmailTaken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
        return response.json();
    } catch (error) {
        console.error('Error checking email:', error);
        return { success: false, message: error.message };
    }
}

export async function logoutWithSupabase() {
    try {
        const response = await fetch(`${BackendUrl}/api/logoutWithSupabase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json();
    } catch (error) {
        console.error('Error during logout:', error);
        return { success: false, message: error.message };
    }
}

export async function tokenDecode(token) {
    try {
        const response = await fetch(`${BackendUrl}/api/decodingaccesstoken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });
        const userData = await response.json();
        console.log("userData insie tokenDecode", userData);
        return userData;
    } catch (error) {
        console.error('Error decoding access token:', error);
        return { success: false, message: error.message };
    }
}
export async function deleteNotification(id) {
    try {
        const response = await fetch(`${BackendUrl}/api/delete-notification/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Failed to delete notification');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deleting notification:', error);
        throw error;
    }
}

export async function fetchNotifications(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-notifications?username=${username}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}
export async function verifyingEmail(email) {
    try {
        const response = await fetch(`${BackendUrl}/api/verify-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            throw new Error('Failed to verify email');
        }

        return await response.json();
    } catch (error) {
        console.error('Error verifying email:', error);
        throw error;
    }
}
export const registerclient = async (username, password, email) => {
    try {
        const response = await fetch(`${BackendUrl}/api/registerclient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, email })
        });
        const data = await response.json();
        console.log("data", data);
        return data;
    } catch (error) {
        console.error('Error registering client:', error);
        throw error;
    }
}

export const register = async (username, password, email) => {
    try {
        const response = await fetch(`${BackendUrl}/api/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
                email,
                
            })
        });

        // Add this to debug the response
        console.log('Register Response:', {
            status: response.status,
            statusText: response.statusText
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Registration failed');
        }

        return response;
    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
};

function getLogoPath(exchange) {
    const logoMap = {
      binance: '/exchangeLogos/BinanceLogo.svg',
      kucoin: '/exchangeLogos/KuCoin.svg',
      bitget: '/exchangeLogos/BitGetLogosvg.svg',
      bybit: '/exchangeLogos/Bybit.svg',
    };
  
    return logoMap[exchange] || null; // Return null or a default image path if the logo is not found
  }

export async function fetchAccountData(username) {
    try {
        console.log("Fetching user assets for username :", username);
        const response = await fetch(`${BackendUrl}/api/account-data/${username}`);
        const data = await response.json();

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Add logo URL to each asset
        for (const asset of data) {
            asset.logo = getLogoPath(asset.logo);
        }

        return data;
    } catch (error) {
        console.error('Error fetching account data:', error);
        return null;
    }
}
export async function deleteExchange(exchangeData) {
    console.log("Deleting exchange inside api:", exchangeData);
    try {
        const response = await fetch(`${BackendUrl}/api/delete-exchange`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(exchangeData),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting exchange:', error);
        return { success: false, message: error.message };
    }
}

export async function saveUser(user) {
    try {
        const response = await fetch(`${BackendUrl}/api/save-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error saving user:', error);
        return { success: false, message: error.message };
    }
}


export async function addExchange(exchangeData) {
    try {
        const response = await fetch(`${BackendUrl}/api/add-exchange`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(exchangeData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error adding exchange:', error);
        return { success: false, message: error.message };
    }
}
export async function getConnectedExchanges(username) {
    try {
      const response = await fetch(`${BackendUrl}/api/connected-exchanges?username=${username}`);
      if (!response.ok) {
        throw new Error('Failed to fetch connected exchanges');
      }
      const data = await response.json();
      return data.exchanges; // Assuming the response contains an array of exchange names
    } catch (error) {
      console.error('Error fetching connected exchanges:', error);
      return [];
    }
  }

export async function executeSwap(swapData) {
    try {
        const response = await fetch(`${BackendUrl}/api/swap`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(swapData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error executing swap:', error.message);
        throw new Error(error.message || 'An error occurred');
    }
}
export async function getActiveTrades(username) {
    const response = await fetch(`${BackendUrl}/api/trades/active-trades/${username}`);
    if (!response.ok) {
        throw new Error('Failed to fetch active trades');
    }
    const data = await response.json();
    return data;
}
export async function fetchTradeHistory(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/trades/history/${username}`);
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error('Failed to fetch trade history');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching trade history:', error.message);
        throw error;
    }
}

export async function fetchLimitOrders(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/trades/limit-orders/${username}`);
        if (!response.ok) {
            throw new Error('Failed to fetch limit orders');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching limit orders:', error.message);
        throw error;
    }
}

export async function cancelLimitOrder(orderData) {
    try {
        const response = await fetch(`${BackendUrl}/api/trades/cancel-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Failed to cancel order');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error canceling order:', error.message);
        throw error;
    }
}

export async function AutomationSwitch(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/user/automation`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        });

        // Check if the response is okay
        if (!response.ok) {
            throw new Error('Failed to update automation status');
        }

        // Parse the response as JSON
        const data = await response.json();
        return data; 
    } catch (error) {
        console.error('Error updating automation status:', error);
        throw error;
    }
}
export async function fetchUltraData15M(requestData) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-ultra15M`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Failed to fetch ultra data');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error loading ultra data:', error.message);
        throw error;
    }
}

export async function fetchUltraData1D(requestData) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-ultra1D`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Failed to fetch ultra data');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error loading ultra data:', error.message);
        throw error;
    }
}


export async function fetchGoogleSheetsTokens() {
    try {
        const response = await fetch(`${BackendUrl}/api/google-sheets-tokens`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching Google Sheets tokens:', error);
        throw error;
    }
}
export async function fetchTokensByMarketCap() {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-tokens-by-market-cap`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching tokens by market cap:', error);
        throw error;
    }
}



export async function fetchDailyProfitLoss(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetching-daily-profit-loss/${username}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching daily profit and loss:', error);
        throw error;
    }
}

export async function fetchSystemMetrics(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/system-metrics/${username}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching system metrics:', error);
        throw error;
    }
}

export async function fetchAllMetrics(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/all-metrics/${username}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching all metrics:', error);
        throw error;
    }
}

export async function AskGptPromt(userInput, username){
    try {
        const response = await fetch(`${BackendUrl}/api/spekule-gpt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userInput , username }),
        });

        
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error communicating with the server:', error);
      }
}
export async function fetchOrderBookforHistogram(symbol) {
    try {
            const response = await axios.get(`${BackendUrl}/api/orderbookForHistogram`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            params: { symbol },
          });
        
          const data = response.data;
          return data;
      } catch (error) {
        console.error('Error fetching order book data:', error);
        return [];
      }
}

export async function fetchCurrentPrice(symbol){
    try {
        const response = await axios.get(`${BackendUrl}/api/current-price`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            params: { symbol },
          });
          const data = response.data;
          return data;
      } catch (error) {
        console.error('Error fetching current price:', error);
        return [];
      }
}

export async function fetchNews() {
    try {
        const response = await axios.get(`${BackendUrl}/api/news`);
        return response.data;
    } catch (error) {
        console.error('Error fetching news:', error);
        return [];
    }

    
}



export async function verifyGoogleToken(token) {
    try {
        const response = await fetch(`${BackendUrl}/api/verify-google-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error verifying Google token:', error);
        throw error;
    }
}

export async function resetPassword(token, newPassword) {
    const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
    });

    if (!response.ok) {
        throw new Error('Failed to reset password');
    }

    return response.json();
}