
import { SpaceBackground } from './ParticleComponent';
import { Heroing } from './Heros';
import { Introduction } from './Introduction';
import { InvestmentPhilosophy } from './InvestmentPhilosophy';
import { Targeting } from './Targeting';
import { Features } from './Features';
import { Navbar } from './Navbar';
import { CTASection } from './CTAsection';
export function LandingPage() {


  return (
    <main className="landing-page min-h-screen bg-black overflow-hidden">
      <SpaceBackground />
      <Navbar />
      <Heroing />
      <Introduction />
      <InvestmentPhilosophy />
      <Targeting />
      <Features />
      <CTASection />
    </main>
  );
}
  

  