   // client/src/components/ProtectedRoute.js
   import React from 'react';
   import { useUser } from '../context/UserContext';
   import { Navigate } from 'react-router-dom';

   const ProtectedRoute = ({ children, allowedRoles }) => {
     const { user } = useUser();

     if (!allowedRoles.includes(user.role)) {
       return <Navigate to="/unauthorized" />;
     }

     return children;
   };

   export default ProtectedRoute;