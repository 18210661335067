
import { UltraComponent } from './tradingComponents/UltraComponent';
import { Sidebar } from './Sidebar';
import { TopNav } from './top-nav';
import Navigation from './ClientPortalComponents/ui/navigation';
import { useUser } from '../context/UserContext';
export default function UltraPage() {
    const { user } = useUser();
    const UserRole = user.role;

    if (UserRole === 'normal') {
        return (  
            <div className="flex h-screen bg-background">
            <Sidebar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <TopNav />
          <main className="flex-1 overflow-y-auto p-4">
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-center mb-4 font-bold text-white glow">Ultra Heat-map</h2>
              <div className="flex justify-center">
                <UltraComponent />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  } else if (UserRole === 'client') {
    return (
        <div className="flex flex-col flex-1 overflow-hidden">
        <Navigation />
        <main className="flex-1 overflow-y-auto p-4">
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-center mb-4 font-bold text-white glow">Ultra Heat-map</h2>
              <div className="flex justify-center">
                <UltraComponent />
              </div>
            </div>
          </main>
        </div>
    );
  }
}